import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import {
    Link,
    useLocation,
    useNavigate,
    useSearchParams,
} from 'react-router-dom';
import { IRequestContactInfo, IStartupFormState } from '../../models/Dtos';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { toast } from 'react-toastify';
import { useBooleanState } from '../../utilities/hooks';
import { selectW350Styles } from '../../constants/styles';
import Button from '../basics/Button';
import PhoneInput from 'react-phone-number-input';
import { TailSpin } from 'react-loader-spinner';
import { cancelStartup, changeStartupContact } from '../../api/apis';

const StartupRequestChange = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const exhibitorId = searchParams.get('exhibitorId');
    const [selectedRequestChange, setSelectedRequestChange] = useState<string>();
    const [isSending, startSending, stopSending] = useBooleanState();
    const startupFormState = (location.state as IStartupFormState);

    const defaultValues: IRequestContactInfo = {
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
    };
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<IRequestContactInfo>({ defaultValues });

    const requestTypeOptions = useMemo(() => {
        return [
            { value: 'changeContactInfo', label: t("startupRequestChange.changeContactInfo.label") },
            { value: 'cancelStartup', label: t("startupRequestChange.cancelStartup.label") },
        ];
    }, [t]);

    const handleSelectedRequestChange = (newValue: any) => {
        const newSelectedReason = (newValue && newValue.currentTarget) ? newValue.currentTarget.value : undefined;
        setSelectedRequestChange(newSelectedReason);
    };

    const handleStartupCancel = async () => {
        if (startupFormState.startup) {
            startSending();
            try {
                await cancelStartup(startupFormState.startup.spExhibitorId);
                toast.success(`${t('startupRequestChange.successes.cancelStartup')} (${startupFormState.startup.spName})`);
                navigate(`/startups?exhibitorId=${exhibitorId}`);
            } catch {
                toast.error(t('startupRequestChange.errors.cancelStartup'));
            } finally {
                stopSending();
            }
        }
    };

    const onSubmitContactChange = async (data: IRequestContactInfo) => {
        if (startupFormState.startup) {
            startSending();
            try {
                await changeStartupContact(startupFormState.startup.spExhibitorId, data);
                toast.success(`${t(`startupRequestChange.successes.changeContactInfo`)} (${startupFormState.startup.spName})`);
                navigate(`/startups?exhibitorId=${exhibitorId}`);
            } catch {
                toast.error(t(`startupRequestChange.errors.changeContactInfo`));
            } finally {
                stopSending();
            }
        }
    }

    return (
        <>
            {startupFormState.startup && (
                <>
                    <h1 className="titleImage">
                        Startup: {startupFormState.startup?.spName}
                    </h1>
                    <Link to={`/startups?exhibitorId=${exhibitorId}`}>
                        <i className="fas fa-arrow-left"></i>
                        {t('common.back')}
                    </Link>
                    <form className="margin" noValidate>
                        <div className="field">
                            <label htmlFor="requestChangeType">
                                {t('startupRequestChange.selectChange')} :
                            </label>
                            <div >
                                <RadioGroup
                                    row
                                    className="request-change-type-selector"
                                    aria-labelledby={t("startupRequestChange.selectChange")}
                                    name="requestChangeType"
                                    onChange={handleSelectedRequestChange}
                                    value={selectedRequestChange}>
                                    {requestTypeOptions.map((d) => (<FormControlLabel key={d.value} label={d.label} value={d.value} className={d.value === selectedRequestChange ? "selected" : ""} control={<Radio />} />))}
                                </RadioGroup>
                            </div>
                        </div>
                        {selectedRequestChange === 'cancelStartup' && (<>
                            <h3>{t("startupRequestChange.cancelStartup.details")}</h3>
                            <div className="field">
                                <Button
                                    type="button"
                                    icon={<i className="fas fa-paper-plane"></i>}
                                    onClick={handleStartupCancel}
                                    label={t('startupRequestChange.submit')}
                                    disabled={isSending}
                                />
                            </div>
                        </>)}
                    </form>
                    {selectedRequestChange === 'changeContactInfo' && (<>
                        <h3>{t("startupRequestChange.changeContactInfo.details")}</h3>
                        <div className="field">
                            <label htmlFor="firstName">{t("startupForm.firstName")}</label>
                            <input
                                type="text"
                                {...register('firstName', {
                                    required: {
                                        value: true,
                                        message: t('common.errors.required')
                                    }
                                })}
                                className="input w-350"
                                maxLength={80}
                            />
                            {errors.firstName && (
                                <p className="formError">{errors.firstName.message}</p>
                            )}
                        </div>
                        <div className="field">
                            <label htmlFor="lastName">{t("startupForm.lastName")}</label>
                            <input
                                className="input w-350"
                                type="text"
                                {...register('lastName', {
                                    required: {
                                        value: true,
                                        message: t('common.errors.required')
                                    }
                                })}
                                maxLength={80}
                            />
                            {errors.lastName && (
                                <p className="formError">{errors.lastName.message}</p>
                            )}
                        </div>
                        <div className="field">
                            <label htmlFor="email">{t("startupForm.email")}</label>
                            <input
                                className="input w-350"
                                type="text"
                                {...register('email', {
                                    required: {
                                        value: true,
                                        message: t('common.errors.required')
                                    }
                                })}
                                maxLength={150}
                            />
                            {errors.email && (
                                <p className="formError">{errors.email.message}</p>
                            )}
                        </div>
                        <div className="field">
                            <label htmlFor="phone">{t("startupForm.phone")}</label>
                            <Controller
                                control={control}
                                name="phone"
                                defaultValue={''}
                                render={({
                                    field: { onChange, value }
                                }) => (
                                    <PhoneInput
                                        className="input w-350"
                                        placeholder={t("startupForm.phone")}
                                        value={value}
                                        styles={selectW350Styles}
                                        onChange={onChange} />
                                )}
                                rules={{
                                    minLength: 3,
                                    required: {
                                        value: true,
                                        message: t('common.errors.required')
                                    }
                                }}
                            />
                            {errors.phone && (
                                <p className="formError">{errors.phone.message}</p>
                            )}
                        </div>
                        <div className="field">
                            <Button
                                type="button"
                                icon={<i className="fas fa-paper-plane"></i>}
                                onClick={handleSubmit((data) => onSubmitContactChange(data))}
                                label={t('startupRequestChange.submit')}
                                disabled={isSending}
                            />
                        </div>
                    </>)}
                    {isSending && (
                        <TailSpin
                            color="#1d6ff7"
                            height={30}
                            width={30}
                            wrapperClass="loader"
                        />
                    )}
                </>
            )}
        </>
    );
};

export default StartupRequestChange;