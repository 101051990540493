import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import profilePlaceholder from '../../images/profile-placeholder.png';

interface ProfilePictureProps {
    src?: string;
    name?: string;
    disabled?: boolean;
    onChange?: (file: File) => Promise<File>;
    onImageLoad?: (image: HTMLImageElement) => void;
    customClassName?: string;
    value?: File;
}

const ProfilePicture = ({
    src = profilePlaceholder,
    name,
    disabled,
    onChange,
    onImageLoad,
    customClassName,
    value,
}: ProfilePictureProps) => {
    const [inputValue, setInputValue] = useState<File>();
    const [changing, setChanging] = useState<boolean>(false);
    const imgRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        if (value) setInputValue(value);
    }, [value]);

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];

        if (file && imgRef.current) {
            if (onChange) {
                setChanging(true);
                onChange(file)
                    .then((res) => {
                        if (imgRef.current) {
                            if (res) {
                                setInputValue(res);
                                if (res.type.includes('image/')) {
                                    imgRef.current.src = URL.createObjectURL(res);
                                    imgRef.current.onload = (e) => {
                                        if (onImageLoad) {
                                            onImageLoad(e.currentTarget as HTMLImageElement);
                                        }
                                    }
                                } else {
                                    imgRef.current.src = profilePlaceholder;
                                }
                            }
                        }
                    })
                    .finally(() => {
                        setChanging(false);
                    });
            }
            else {
                setInputValue(file);
                if (file.type.includes('image/')) {
                    imgRef.current.src = URL.createObjectURL(file);
                    imgRef.current.onload = (e) => {
                        if (onImageLoad) {
                            onImageLoad(e.currentTarget as HTMLImageElement);
                        }
                    }
                } else {
                    imgRef.current.src = profilePlaceholder;
                }
            }
        }
    };

    return (
        <div className={`profilePicture ${customClassName ? customClassName : ''} ${changing ? 'changing-picture' : ''}`}>
            {changing && (<TailSpin
                color="#1d6ff7"
                height={30}
                width={30}
                wrapperClass="loader-left margin"
            />)}
            <img ref={imgRef} src={src} alt=""/>
            <input
                className="file-input"
                type="file"
                disabled={disabled === undefined ? false : disabled}
                accept="image/*"
                onChange={handleFileChange}
                name={name}
            />
            {!disabled && !changing && (<i className="fas fa-pen"></i>)}

        </div>
    )
};

export default ProfilePicture;