import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useBooleanState } from '../../utilities/hooks';
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css';
import Modal from '../basics/Modal';
import { toast } from 'react-toastify';
import { BlobDto, SpeakerWithPhotoDto } from '../../models/Dtos';
import { TailSpin } from 'react-loader-spinner';
import { useDebounceEffect } from './UseDebounceEffect';
import { BlobServiceClient } from '@azure/storage-blob';
import { FormControlLabel, FormGroup, Slider, Switch } from '@mui/material';
import { getImage, getSpeakersWithPhoto, updateSpeakerPhoto, uploadSpeakerPhoto } from '../../api/apis';

const TO_RADIANS = Math.PI / 180;
const minSquareSizeInPixel = 250;

export interface SpeakersListProps {
    filter: "official" | "partner";
}
const SpeakersList = (props: SpeakersListProps) => {
    const { filter } = props;
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const accessToken = searchParams.get('t');
    const [speakers, setSpeakers] = useState<SpeakerWithPhotoDto[]>();
    const [selectedSpeaker, setSelectedSpeaker] = useState<SpeakerWithPhotoDto>();
    const [isFetchLoading, startFetchLoading, stopFetchLoading] = useBooleanState(true);
    const [isUpdatingPicture, startIsUpdatingPicture, stopIsUpdatingPicture] = useBooleanState(false);
    const [isOpenEditPictureModal, openEditPictureModal, closeEditPictureModal] = useBooleanState(false);
    const [isPreview, showPreview, hidePreview] = useBooleanState(false);

    const [imgSrc, setImgSrc] = useState('');
    const previewCanvasRef = useRef<HTMLCanvasElement>(null);
    const imgRef = useRef<HTMLImageElement>(null);
    const [crop, setCrop] = useState<Crop>();
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);
    const [aspect, setAspect] = useState<number | undefined>(1); //16 / 9
    const [circular, setCircular] = useState<boolean>(true);

    async function canvasPreview(
        image: HTMLImageElement,
        canvas: HTMLCanvasElement,
        crop: PixelCrop,
        scale = 1,
        rotate = 0,
    ) {
        try {
            sessionStorage.removeItem("cropped-image");
        } catch (err) {
            console.error(`Error on removing: ${err}`);
        }
        const ctx = canvas.getContext('2d');

        if (!ctx) {
            throw new Error('No 2d context');
        }

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        // devicePixelRatio slightly increases sharpness on retina devices
        // at the expense of slightly slower render times and needing to
        // size the image back down if you want to download/upload and be
        // true to the images natural size.
        const pixelRatio = window.devicePixelRatio;
        // const pixelRatio = 1

        canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
        canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

        ctx.scale(pixelRatio, pixelRatio);
        ctx.imageSmoothingQuality = 'high';

        const cropX = crop.x * scaleX;
        const cropY = crop.y * scaleY;

        const rotateRads = rotate * TO_RADIANS;
        const centerX = image.naturalWidth / 2;
        const centerY = image.naturalHeight / 2;

        ctx.save();

        // 5) Move the crop origin to the canvas origin (0,0)
        ctx.translate(-cropX, -cropY)
        // 4) Move the origin to the center of the original position
        ctx.translate(centerX, centerY)
        // 3) Rotate around the origin
        ctx.rotate(rotateRads)
        // 2) Scale the image
        ctx.scale(scale, scale)
        // 1) Move the center of the image to the origin (0,0)
        ctx.translate(-centerX, -centerY)
        ctx.drawImage(
            image,
            0,
            0,
            image.naturalWidth,
            image.naturalHeight,
            0,
            0,
            image.naturalWidth,
            image.naturalHeight,
        );

        ctx.restore();


        try {
            sessionStorage.setItem("cropped-image", canvas.toDataURL("image/jpeg", 75));
        } catch (err) {
            console.error(`Error: ${err}`);
        }
    }

    function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined) // Makes crop preview update between images.
            const reader = new FileReader()
            reader.addEventListener('load', () =>
                setImgSrc(reader.result?.toString() || ''),
            )
            reader.readAsDataURL(e.target.files[0])
        }
    }

    async function onSelectFileFromUrl(imgUrl: string) {
        await getImage(imgUrl, accessToken || '')
            .then((res) => {
                setCrop(undefined) // Makes crop preview update between images.
                const reader = new FileReader()
                reader.addEventListener('load', () =>
                    setImgSrc(reader.result?.toString() || ''),
                )
                reader.readAsDataURL(res.data);
            });
    }

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        if (aspect) {
            const { width, height } = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }

    useDebounceEffect(
        async () => {
            if (
                !isPreview && 
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                )
            }
        },
        100,
        [completedCrop, scale, rotate, isPreview],
    )

    function handleToggleAspectClick(useSquare: boolean) {
        if (!useSquare) {
            setAspect(undefined)
        } else if (imgRef.current) {
            const { width, height } = imgRef.current
            setAspect(1); //16 / 9)
            setCrop(centerAspectCrop(width, height, 1)) //16 / 9))
        }
    }

    // This is to demonstate how to make and center a % aspect crop
    // which is a bit trickier so we use some helper functions.
    function centerAspectCrop(
        mediaWidth: number,
        mediaHeight: number,
        aspect: number,
    ) {
        return centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 90,
                },
                aspect,
                mediaWidth,
                mediaHeight,
            ),
            mediaWidth,
            mediaHeight,
        )
    }

    const uploadPicture = async () => {
        return new Promise<SpeakerWithPhotoDto>((resolve, reject) => {
            if (!selectedSpeaker || !previewCanvasRef?.current || !accessToken) {
                reject('no picture');
            } else {
                var blob: BlobDto;
                previewCanvasRef.current.toBlob(async (file) => {
                    const name = imgRef.current?.src.split('/').pop();
                    if (file && name) {
                        var response = await uploadSpeakerPhoto(name, file.type, accessToken);

                        blob = (response.data as BlobDto);

                        var string = blob.url.substring(0, blob.url.length - 1) + blob.sas;
                        const blobServiceClient = new BlobServiceClient(string);

                        const containerClient = blobServiceClient.getContainerClient(blob.container);

                        const blobClient = containerClient.getBlockBlobClient(blob.blobName);

                        // create blobClient for container
                        const options = { blobHTTPHeaders: { blobContentType: file.type } };
                        await blobClient.uploadData(file, options);

                        var updatedSpeaker = await updateSpeakerPhoto(selectedSpeaker.id, blob, accessToken);

                        if (updatedSpeaker.data)
                            resolve(updatedSpeaker.data);
                        else
                            reject()
                    }
                    else {
                        reject('no file found');
                    }
                }, "image/jpeg", 75);
            }
        });
    }

    const handleUpdatePicture = async () => {
        if (speakers && selectedSpeaker) {
            try {
                startIsUpdatingPicture();
                var updatedSpeaker = await uploadPicture();

                if (updatedSpeaker) {
                    const currentSpeakerIdx = speakers.findIndex(s => s.id === selectedSpeaker.id);

                    //setSpeakers([...speakers.slice(0, currentSpeakerIdx), updatedSpeaker, ...speakers.slice(currentSpeakerIdx + 1)]);
                    // Remove it
                    setSpeakers([...speakers.slice(0, currentSpeakerIdx), ...speakers.slice(currentSpeakerIdx + 1)]);
                    setSelectedSpeaker(undefined);

                    toast.success(`${t('speakersDashboard.successes.editPicture')} (${updatedSpeaker.firstName} ${updatedSpeaker.lastName})`);

                    closeEditPictureModal();
                    hidePreview();
                    setImgSrc('');
                }
                else {
                    toast.error(t('speakersDashboard.errors.editPicture'));
                }
            } catch (ex) {
                console.log(ex);
                toast.error(t('speakersDashboard.errors.editPicture'));
            } finally {
                stopIsUpdatingPicture();
            }
        }
    };

    useEffect(() => {
        startFetchLoading();
        if (!speakers && accessToken) {
            getSpeakersWithPhoto(filter, accessToken)
                .then((results) => {
                    setSpeakers(results.data as SpeakerWithPhotoDto[]);
                }).finally(() => {
                    stopFetchLoading();
                });
        }
        else if (!accessToken) {
            setSpeakers([]);
            stopFetchLoading();
        }
    }, [speakers, accessToken, filter]);

    const handleEditSpeakerPicture = (speaker: SpeakerWithPhotoDto) => {
        setSelectedSpeaker(speaker);
        setScale(1);
        setRotate(0);
        openEditPictureModal();
        if (speaker.photo) onSelectFileFromUrl(speaker.photo.url);
        setCompletedCrop(undefined);
    };

    return (
        <>
            <h1>{t('speakersDashboard.title')}</h1>
            {isFetchLoading && !speakers ? (
                <TailSpin
                    color="#1d6ff7"
                    height={50}
                    width={50}
                    wrapperClass="loader margin"
                />
            ) : (<>
                <div>
                    <div style={{ display: 'flex', flexDirection: 'row', columnGap: '20px', justifyContent: 'space-around' }}>
                        <div style={{ flex: 1 }}>
                            {speakers && speakers.length > 0 && (
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th style={{ width: 250 }}>
                                                <span>{t('speakersDashboard.name')}</span>
                                            </th>
                                            <th style={{ width: 150 }}>
                                                <span>{t('speakersDashboard.photo')}</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {speakers.map(row => (
                                            <tr key={row.id}>
                                                <td>{row.firstName} {row.lastName}</td>
                                                <td>{
                                                    row.photo?.thumbSmallUrl ?
                                                        (<img src={row.photo.thumbSmallUrl} alt="Speaker" style={{ cursor: 'pointer' }} onClick={() => handleEditSpeakerPicture(row)} />) :
                                                        (<>-</>)
                                                }</td>
                                            </tr>)
                                        )}
                                    </tbody>
                                </table>
                            )}
                        </div>
                        <div style={{ flex: 1 }}>

                        </div>
                    </div>
                </div>
            </>)}

            {isOpenEditPictureModal && (
                <Modal>
                    <h3>{t('speakersDashboard.updatePicture')}</h3>
                    {(isUpdatingPicture || !setSelectedSpeaker) && (
                        <TailSpin
                            color="#1d6ff7"
                            height={50}
                            width={50}
                            wrapperClass="loader margin"
                        />
                    )}

                    <div style={{ display: isPreview ? 'flex' : 'none' }} className="image-container">
                        {/* <h3>Preview</h3> */}
                        {!!completedCrop && (<>
                            <canvas
                                ref={previewCanvasRef}
                                style={{
                                    border: '1px solid #C0C0C0',
                                    objectFit: 'contain',
                                    width: completedCrop.width,
                                    height: completedCrop.height,
                                }}
                            />
                            {/* <div>WxH : <span>{Math.round(completedCrop.width)}px</span> x <span>{Math.round(completedCrop.height)}px</span> </div> */}
                        </>)}
                    </div>
                    <div style={{ display: 'none', columnGap: 15 }}>
                        <FormGroup>
                            <FormControlLabel control={<Switch
                                checked={circular}
                                onChange={(e, checked) => setCircular(checked)} />}
                                label="Circular" />
                        </FormGroup>
                        <FormGroup>
                            <FormControlLabel control={<Switch
                                checked={!!aspect}
                                onChange={(e, checked) => handleToggleAspectClick(checked)} />}
                                label="Square" />
                        </FormGroup>
                    </div>

                    <div className="Crop-Controls" style={{ display: isPreview ? 'none' : 'block' }}>
                        {!!imgSrc ? (<>
                            {/* <h3>Source</h3> */}
                            {/* <div style={{ display: 'none ' }}>WxH : <span>{imgRef.current?.width}px</span> x <span>{imgRef.current?.height}px</span></div> */}
                            <div className="image-container">
                                <ReactCrop
                                    crop={crop}
                                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                                    onComplete={(c) => setCompletedCrop(c)}
                                    aspect={aspect}
                                    minWidth={minSquareSizeInPixel}
                                    minHeight={minSquareSizeInPixel}
                                    circularCrop={circular}
                                >
                                    <img
                                        ref={imgRef}
                                        alt="Crop me"
                                        src={imgSrc}
                                        style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                                        onLoad={onImageLoad}
                                    />
                                </ReactCrop>
                            </div>
                        </>) : (<TailSpin
                            color="#1d6ff7"
                            height={50}
                            width={50}
                            wrapperClass="loader margin"
                        />)}

                        <div style={{ display: 'flex', justifyContent: 'center', columnGap: 15, marginTop: 20 }}>
                            <div style={{ display: 'flex', justifyContent: 'center', columnGap: 15 }}>
                                <div>
                                    <label htmlFor="scale-input">Zoom (x {scale}) :</label>
                                </div>
                                <div style={{ width: 100, flex: 1 }}>
                                    <Slider min={1} max={5} step={0.2} id="scale-input"
                                        value={scale}
                                        onChange={(e, val) => setScale(Array.isArray(val) ? val[0] : val)}
                                        disabled={!imgSrc}></Slider>
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', columnGap: 15 }}>
                                <div>
                                    <label htmlFor="rotate-input">Rotate ({rotate}°): </label>
                                </div>
                                <div style={{ width: 100, flex: 1 }}>
                                    <Slider min={0} max={360} step={5} id="rotate-input"
                                        value={rotate}
                                        onChange={(e, val) => setRotate(Math.min(180, Math.max(-180, Array.isArray(val) ? val[0] : val)))}
                                        disabled={!imgSrc}></Slider>
                                </div>
                            </div>
                        </div>
                        <input type="file" accept="image/*" onChange={onSelectFile} title="Upload a picture" />

                    </div>

                    {isPreview ? (<div className="buttons">
                        <button className="button" onClick={hidePreview}>
                            <i className="fas fa-times"></i>
                            {t('speakersDashboard.edit')}
                        </button>
                        <button className="button" onClick={handleUpdatePicture}>
                            <i className="fas fa-check"></i>
                            {t('speakersDashboard.confirm')}
                        </button>
                    </div>) : (<div className="buttons">
                        <button className="button" onClick={() => {
                            closeEditPictureModal();
                            hidePreview();
                            setImgSrc('');
                        }}>
                            <i className="fas fa-times"></i>
                            {t('common.cancel')}
                        </button>
                        <button className="button" onClick={() => showPreview()}>
                            <i className="fas fa-check"></i>
                            {t('speakersDashboard.preview')}
                        </button>
                    </div>)}
                </Modal>
            )}
        </>
    )
}

export default SpeakersList;