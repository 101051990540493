import React, {
    ChangeEvent,
    createRef,
    useEffect,
    useState
} from 'react';

interface FileInputProps {
    label: string;
    name: string;
    onChange?: (value: File| null) => void;
    value?: File | null;
    accept?: string;
    placeholder?: string;
}

const FileInput = ({
    label,
    name,
    onChange,
    value,
    accept,
    placeholder
}: FileInputProps) => {
    const fRef = createRef<HTMLInputElement>();
    const [file, setFile] = useState<File | null>(value || null);

    useEffect(() => {
        if(typeof value !== 'undefined') setFile(value);
        if (value === null && fRef.current) {
            fRef.current.value = "";
        }
    }, [fRef, value]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            onChange ? onChange(files[0]) : setFile(files[0]);
        } else {
            setFile(null);
        }
    };

    return (
        <div className="file has-name">
            <label className="file-label">
                <input
                    ref={fRef}
                    className="file-input"
                    type="file"
                    name={name}
                    onChange={handleChange}
                    accept={accept}
                />
                <span className="file-cta">
                    <span className="file-icon">
                        <i className="fas fa-upload"></i>
                    </span>
                    <span className="file-label">
                        {label}
                    </span>
                </span>
                <span className="file-name">
                    {file?.name || placeholder}
                </span>
            </label>
        </div>
    );
};

export default FileInput;