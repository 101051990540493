import { StartupPartnerDto } from '../models/Dtos';

export const getPresenceArrayFromDto = (dto: StartupPartnerDto) => {
    return Object.entries(dto)
    .filter(([key, value]) => key.includes('Presence') && value)
    .map(([key]: any) => {
        if(key.includes('Online')) {
            return key.substring(10).toLowerCase();
        }
        return key.substring(10, 13).toLowerCase();
    });
};

export const getPresenceOptionsFromDto = (dto: StartupPartnerDto) => {
    const presenceArray = getPresenceArrayFromDto(dto);
    return presenceArray.map(presence => {
        return {
            label: presence,
            value: presence
        };
    });
};