import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';
import { SSoUrlDto } from '../../models/Dtos';
import Button from '../basics/Button';
import { getInvitations } from '../../api/apis';

const Invitations = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const [error, setError] = useState<string>();
    const [invitations, setInvitations] = useState<SSoUrlDto[]>();
    const p = searchParams.get('p');

    useEffect(() => {
        if (p) {
            const decoded = atob(p).split('|');
            if (decoded.length === 2) {
                getInvitations(decoded[0], decoded[1])
                    .then((res) => {
                        setInvitations(res.data);
                    }).catch((err) => {
                        setError('Cannot retrieve your invitations.');
                        setInvitations([]);
                    });
            }
        } else {
            setError('Cannot retrieve your invitations.');
            setInvitations([]);
        }
    }, [p]);

    return (<div style={{ margin: 20 }}>
        <h1>{t('invitations.title')}</h1>
        {error && (<p className="formError">{error}</p>)}
        {invitations ? (
            <div className='flex-container gap'>
                { invitations.map((inv, idx) => <Button type="button" onClick={() => window.location.assign(inv.url + "&lang=en")} label={`Access your invitations module${idx > 1 ? `${idx}` : ""}`} />)}
            </div>) : (
            <TailSpin
                color="#1d6ff7"
                height={50}
                width={50}
                wrapperClass="loader margin"
            />
        )}
    </div>);
}

export default Invitations;