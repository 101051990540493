import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { IWSimilarStartupGroupDto } from '../../models/Dtos';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Button from '../basics/Button';
import { TailSpin } from 'react-loader-spinner';
import { getSimilarStartups } from '../../api/apis';

const MasterStartupsDashboard = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [isFetchLoading, setIsFetchLoading] = useState<boolean>(true);
    const [groups, setGroups] = useState<IWSimilarStartupGroupDto[]>();
    const eventId = searchParams.get('eventId');
    const customerId = searchParams.get('customerId');

    const handleEditClick = useCallback((startupGroup: IWSimilarStartupGroupDto, e: MouseEvent<HTMLButtonElement>) => {
        if (startupGroup) {
            e.preventDefault();
            navigate(`/master/select?customerId=${customerId}&eventId=${eventId}&ids=${startupGroup.startups.map(s => s.id).join(';')}`);
        }
    }, [navigate, eventId, customerId]);

    useEffect(() => {
        if (!groups) {
            // Call API to get all details
            getSimilarStartups()
                .then((result) => {
                    setGroups(result.data);
                    setIsFetchLoading(false);
                }).catch(() => {
                    setGroups([]);
                });
        }
    }, [groups]);

    const boBaseUrl = useMemo(() => {
        const eventId = searchParams.get('eventId');
        const customerId = searchParams.get('customerId');
        return `https://my.inwink.com/${customerId}/e/${eventId}/exhibitors`;
    }, [searchParams]);
    
    return (<div style={{ margin: 20 }}>
        <h1>Détection des doublons potentiels de startups</h1>
        {isFetchLoading ? (
            <TailSpin
                color="#1d6ff7"
                height={50}
                width={50}
                wrapperClass="loader margin"
            />) : (
            <div>
                <div>{groups && groups?.length >= 1 ? "Les doublons de startups suivants ont été détectés" : "Aucun doublon de startup détecté"}</div>
                {groups?.map(grp => (
                    <div 
                        key={grp.key} 
                        style={{paddingBottom: 20, marginTop: 20, borderBottom: '1px solid #C0C0C0'}} 
                        className="is-flex">
                        <div style={{ marginRight: 20 }}>
                            Détection de {grp.startups.length} startups similaires
                        </div>
                        <div style={{ flexGrow: 3 }}>
                                {grp.startups.map(s => (
                                    <div key={s.id}>
                                        {s.name}&nbsp;
                                        <a href={`${boBaseUrl}?entityid=${s.id}`} rel="noopener noreferrer" target="_blank" title="Lien BackOffice"><i className="fas fa-link"></i></a>&nbsp;
                                        {s.isMasterStartup === true && (<i className="fas fa-trophy" title="Master"></i>)}&nbsp;
                                        {s.masterStartupId && (<i className="fas fa-child" title="Child"></i>)}&nbsp;
                                    </div>)
                                )}
                        </div>
                        <div>
                            <Button type="button" onClick={handleEditClick.bind(null, grp)} label="Définir master/enfants" />
                        </div>
                    </div>)
                )}
            </div>)
        }
    </div>);
}

export default MasterStartupsDashboard;