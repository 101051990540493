import { useEffect, useState } from 'react';
import { PartnerStartupsKpiDto } from '../../models/Dtos';
import { useSearchParams } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';
import { getStartupsKpi } from '../../api/apis';

const StartupsOverviewDashboard = () => {
    const [searchParams] = useSearchParams();
    const eventId = searchParams.get('eventId');
    const customerId = searchParams.get('customerId');
    const [kpis, setKpis] = useState<PartnerStartupsKpiDto[]>();

    useEffect(() => {
        if (eventId && customerId) {
            if (!kpis) {
                getStartupsKpi()
                    .then((res) => {
                        setKpis(res.data);
                    }).catch(() => {
                        setKpis([]);
                    });
            }
        }
        else {
            setKpis([]);
        }
    }, [eventId, customerId, kpis]);

    return (<div style={{ margin: 20 }}>
        <h1>Suivi des startups par partenaires</h1>
        {(!kpis) ? (
            <TailSpin
                color="#1d6ff7"
                height={50}
                width={50}
                wrapperClass="loader margin"
            />) : (
            <div className="kpis table">
                <table style={{ width: "100%" }} cellPadding={3}>
                    <thead>
                        <th>Partner</th>
                        <th>SU Draft</th>
                        <th>SU Rejected</th>
                        <th>SU Waiting for approval</th>
                        <th>SU Approved</th>
                        <th>Quota</th>
                    </thead>
                    <tbody>
                        {kpis.map(kpi => {
                            return (
                        <tr key={kpi.id}>
                            <td>{kpi.name}</td>
                            <td>{kpi.draftStartups ? kpi.draftStartups.length : 0}</td>
                            <td>{kpi.rejectedStartups ? kpi.rejectedStartups.length : 0}</td>
                            <td>{kpi.waitingForApprovalStartups ? kpi.waitingForApprovalStartups.length : 0}</td>
                            <td>{kpi.approvedStartups ? kpi.approvedStartups.length : 0}</td>
                            <td>{kpi.quota}</td>
                        </tr>)
                        })}
                    </tbody>
                </table>
            </div>)
        }
    </div>);
}

export default StartupsOverviewDashboard;