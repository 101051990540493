export const selectStyles = {
    control: (provided: any) => ({
        ...provided,
        borderRadius: 0,
        boxShadow: 0
    })
};

export const selectW350Styles = {
    control: (provided: any) => ({
        ...selectStyles.control(provided),
        width: 350
    })
};

export const selectW720Styles = {
    control: (provided: any) => ({
        ...selectStyles.control(provided),
        width: 720
    })
};