import {
  BaseSyntheticEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import Button from "../../components/basics/Button";
import ProfilePicture from "../basics/ProfilePicture";
import thumbnailPlaceholder from "../../images/placeholder.png";
import { toast } from "react-toastify";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Select from "react-select";
import { useBooleanState } from "../../utilities/hooks";
import {
  BlobDto,
  FieldValueListItem,
  IWExhibitorBasicInfoDto,
  IWExhibitorDto,
  SessionDto,
  SpeakerDto,
} from "../../models/Dtos";
import { TextField } from "@mui/material";
import { BlobServiceClient } from "@azure/storage-blob";
import TimePicker from "@mui/lab/TimePicker";
import { TailSpin } from "react-loader-spinner";
import {
  createSession,
  getPublishedStartups,
  getSessionInterests,
  unpublishSession,
  updateSession,
  uploadSessionPhoto,
} from "../../api/apis";

const styleSpeakerInput = {
  height: 40,
  alignSelf: "center",
  width: 250,
  marginBottom: 5,
};

const fakeEmailDomain = "partner-speakers.vivatech.fr";

const SessionForm = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const exhibitorId = searchParams.get("exhibitorId");
  const [isLoading] = useState(false);
  const [isSaving, startSaving, stopSaving] = useBooleanState();
  const [isPublishing, startPublishing, stopPublishing] = useBooleanState();
  const [isUnpublishing, setIsUnpublishing] = useState<boolean>(false);

  const defaultValues = {
    title: "",
    description: "",
    //highlights: false,
    startDate: null,
    //video: '',
    status: "Draft",
    typologieDeSession: null,
    sessionFormat: null,
    //thumbnail: null,
    speakers: [],
    exhibitors: [],
    interestFilter: [],
  };
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitted },
    setValue,
  } = useForm<any>({ defaultValues });
  const {
    fields: speakersFields,
    append: appendSpeaker,
    remove: removeSpeaker,
  } = useFieldArray({
    control,
    name: "speakers",
  });

  //const [typologieDeSession, status, speakers, sessionFormat] = watch(['typologieDeSession', 'status', 'speakers', 'sessionFormat']);
  const [status, speakers] = watch(["status", "speakers"]);
  const [contentLanguage, setContentLanguage] = useState<string>(" - EN");
  const profilePicture = useRef<HTMLImageElement[]>([]);
  const location = useLocation();
  const session = (location.state as any)?.session;
  //const highlightCount = (location.state as any)?.highlightCount;
  const workshopCount = useMemo(() => {
    return (location.state as any)?.workshopCount;
  }, [location]);
  const partnerStatut = useMemo(() => {
    return (location.state as any)?.partnerStatut;
  }, [location]);
  const allSessions = (location.state as any)?.allSessions;
  const [startTimeSlot, setStartTimeSlot] = useState(new Date() || null);
  const [endTimeSlot, setEndTimeSlot] = useState(new Date() || null);
  const [isPreview, setIsPreview] = useState(false);
  const [exhibitors, setExhibitors] = useState<IWExhibitorBasicInfoDto[]>();
  const [interests, setInterests] = useState<FieldValueListItem[]>();
  const hashtagsOptions = useMemo(() => {
    if (interests) {
      return interests
        .map((i) => {
          return {
            value: i.key,
            label: i.labels[i18n.language],
          };
        })
        .sort();
    } else {
      return undefined;
    }
  }, [interests, i18n]);
  const categoryOptions = useMemo(() => {
    const canAddDailySession = partnerStatut.sessionsPerDay > 0;
    return [
      {
        value: "demo",
        label: t("sessionForm.categories.demo"),
        isDisabled: !canAddDailySession,
      },
      {
        value: "masterclass",
        label: t("sessionForm.categories.masterclass"),
        isDisabled: !canAddDailySession,
      },
      {
        value: "pitch",
        label: t("sessionForm.categories.pitch"),
        isDisabled: !canAddDailySession,
      },
      {
        value: "talk",
        label: t("sessionForm.categories.talk"),
        isDisabled: !canAddDailySession,
      },
      {
        value: "workshop",
        label: t("sessionForm.categories.workshop"),
        isDisabled:
          !canAddDailySession && workshopCount >= partnerStatut.workshopTotal,
      },
    ];
  }, [t, workshopCount, partnerStatut]);
  const sessionFormatOptions = useMemo(() => {
    return [
      { value: "in-person", label: t("sessionForm.sessionFormats.in-person") },
    ];
  }, [t]);

  useEffect(() => {
    if (!interests) {
      getSessionInterests().then((res) => {
        setInterests(res.data);
      });
    }
  }, [interests]);

  useEffect(() => {
    if (!exhibitors) {
      getPublishedStartups().then((res) => {
        setExhibitors(res.data as IWExhibitorBasicInfoDto[]);
      });
    }
  }, [exhibitors]);

  const minTime = useMemo(() => {
    let date = new Date();
    date.setHours(8);
    date.setMinutes(0);

    return date;
  }, []);

  const maxTime = useMemo(() => {
    let date = new Date();
    date.setHours(19);
    date.setMinutes(0);

    return date;
  }, []);

  const civilityOptions = useMemo(() => {
    return [
      { value: "mr", label: t("common.civility.mr") },
      { value: "ms", label: t("common.civility.ms") },
      { value: "mx", label: t("common.civility.mx") },
    ];
  }, [t]);

  const daysOptions = useMemo(() => {
    return [
      { value: "2024-05-22T00:00:00", label: t("common.days2024.wed") },
      { value: "2024-05-23T00:00:00", label: t("common.days2024.thu") },
      { value: "2024-05-24T00:00:00", label: t("common.days2024.fri") },
      { value: "2024-05-25T00:00:00", label: t("common.days2024.sat") },
    ];
  }, [t]);

  useEffect(() => {
    if (!session) {
      const date = new Date();
      date.setMinutes(0);

      if (date < minTime) {
        setStartTimeSlot(minTime);
        setEndTimeSlot(new Date(minTime.getTime() + 30 * 60000));
      } else if (date >= maxTime) {
        setStartTimeSlot(new Date(maxTime.getTime() - 30 * 60000));
        setEndTimeSlot(maxTime);
      } else {
        setStartTimeSlot(date);
        setEndTimeSlot(new Date(date.getTime() + 30 * 60000));
      }
    }
  }, [session, minTime, maxTime]);

  useEffect(() => {
    if (!session) {
      setValue("sessionFormat", sessionFormatOptions[0]);
    } else {
      if (session.speakers && civilityOptions) {
        session.speakers.forEach((sp: any) => {
          if (sp.civility)
            sp.civilityOption = civilityOptions.find(
              (co) => co.value === sp.civility
            );
        });
      }

      setValue("title", session.title);
      setValue("description", session.description);
      setValue(
        "typologieDeSession",
        categoryOptions.find((c) => c.value === session.typologieDeSession)
      );
      setValue(
        "sessionFormat",
        sessionFormatOptions.find((c) => c.value === session.sessionFormat)
      );
      //setValue('highlights', session.highlight);
      //setValue('thumbnail', session.thumbnail);
      setValue("speakers", session.speakers);
      setValue(
        "exhibitors",
        session.exhibitors
          ?.filter((e: IWExhibitorDto) => e.id !== exhibitorId)
          .map((e: IWExhibitorDto) => {
            return { value: e.id, label: e.name };
          })
      );
      setValue(
        "interestFilter",
        hashtagsOptions
          ? hashtagsOptions.filter(
              (h) => session.interestFilter?.indexOf(h.value) >= 0
            )
          : []
      );
      const curDay = daysOptions.find(
        (d) =>
          session.startDateTimeSlot &&
          d.value.startsWith(session.startDateTimeSlot.substring(0, 10))
      );
      setValue("date", curDay ?? daysOptions[0]);
      if (session.startDateTimeSlot)
        setStartTimeSlot(new Date(session.startDateTimeSlot));
      if (session.endDateTimeSlot)
        setEndTimeSlot(new Date(session.endDateTimeSlot));
      setValue("status", session.status);
      session.status === "Preview" ? setIsPreview(true) : setIsPreview(false);
      //setValue('video', session.video);

      if (curDay?.value.includes("2024-05-25")) setContentLanguage(" - FR");
    }
  }, [
    exhibitorId,
    session,
    setValue,
    daysOptions,
    civilityOptions,
    categoryOptions,
    sessionFormatOptions,
    hashtagsOptions,
    t,
  ]);

  const onSubmit = async (
    data: any,
    e?: BaseSyntheticEvent,
    publish = false
  ) => {
    if (publish) startPublishing();
    else startSaving();

    var startDateToSend = new Date(data.date.value);
    var endDateToSend = new Date(data.date.value);
    startDateToSend.setHours((startTimeSlot || new Date()).getHours());
    startDateToSend.setMinutes((startTimeSlot || new Date()).getMinutes());
    endDateToSend.setHours((endTimeSlot || new Date()).getHours());
    endDateToSend.setMinutes((endTimeSlot || new Date()).getMinutes());

    const currentSpeakers: SpeakerDto[] = data.speakers;
    if (currentSpeakers) {
      const curTime = new Date().getTime().toString();
      currentSpeakers
        .filter((s) => !s.mail || s.mail.trim() === "")
        .forEach((spk) => {
          // Generate email if not present
          if (!spk.mail) {
            spk.mail = `${(spk.firstName || "").trim()}.${(
              spk.lastName || ""
            ).trim()}-${curTime}@${fakeEmailDomain}`.toLowerCase();
          }
        });
    }

    if (session && exhibitorId) {
      try {
        const body: SessionDto = {
          id: session.id,
          title: data.title,
          description: data.description,
          typologieDeSession: data.typologieDeSession
            ? data.typologieDeSession.value
            : null,
          sessionFormat: data.sessionFormat ? data.sessionFormat.value : null,
          startDateTimeSlot: new Date(
            startDateToSend.getTime() -
              startDateToSend.getTimezoneOffset() * 60000
          ).toISOString(),
          endDateTimeSlot: new Date(
            endDateToSend.getTime() - endDateToSend.getTimezoneOffset() * 60000
          ).toISOString(),
          //highlight: data.highlights,
          //video: data.video,
          //thumbnail: data.thumbnail, // thumbnailPicture?.current ? thumbnailPicture?.current : null,
          interestFilter: data.interestFilter
            ? (data.interestFilter as any[]).map((h) => h.value)
            : [],
          status: publish ? "Preview" : null,
          exhibitors: data.exhibitors?.map((e: any) => e.value),
          speakers: data.speakers,
        };
        await updateSession(exhibitorId, body);

        try {
          if (body.startDateTimeSlot)
            sessionStorage.setItem(
              `selectedSessionDate-${exhibitorId}`,
              body.startDateTimeSlot.substring(0, 10)
            );
          else sessionStorage.removeItem(`selectedSessionDate-${exhibitorId}`);
        } catch {}

        toast.success(`${t("sessionForm.successes.update")} (${body.title})`);
        navigate(`/sessions?exhibitorId=${exhibitorId}`);
      } catch {
        toast.error(t("sessionForm.errors.update"));
        if (publish) stopPublishing();
        else stopSaving();
      }
    } else {
      try {
        const body: SessionDto = {
          title: data.title,
          description: data.description,
          typologieDeSession: data.typologieDeSession
            ? data.typologieDeSession.value
            : null,
          sessionFormat: data.sessionFormat ? data.sessionFormat.value : null,
          startDateTimeSlot: new Date(
            startDateToSend.getTime() -
              startDateToSend.getTimezoneOffset() * 60000
          ).toISOString(),
          endDateTimeSlot: new Date(
            endDateToSend.getTime() - endDateToSend.getTimezoneOffset() * 60000
          ).toISOString(),
          //highlight: data.highlights,
          //video: data.video,
          //thumbnail: data.thumbnail, // thumbnailPicture?.current ? thumbnailPicture?.current : null,
          interestFilter: data.interestFilter
            ? (data.interestFilter as any[]).map((h) => h.value)
            : [],
          status: publish ? "Preview" : data.status,
          exhibitors: data.exhibitors?.map((e: any) => e.value),
          speakers: data.speakers,
        };

        await createSession(exhibitorId!, body);

        try {
          if (body.startDateTimeSlot)
            sessionStorage.setItem(
              `selectedSessionDate-${exhibitorId}`,
              body.startDateTimeSlot.substring(0, 10)
            );
          else sessionStorage.removeItem(`selectedSessionDate-${exhibitorId}`);
        } catch {}

        toast.success(`${t("sessionForm.successes.create")} (${body.title})`);
        navigate(`/sessions?exhibitorId=${exhibitorId}`);
      } catch {
        toast.error(t("sessionForm.errors.create"));

        if (publish) stopPublishing();
        else stopSaving();
      }
    }
  };

  const handlePublish = async () => {
    await handleSubmit((data, e) => onSubmit(data, e, true))();
  };

  const handleUnpublish = async () => {
    setIsUnpublishing(true);
    try {
      await unpublishSession(exhibitorId!, session.id);
      toast.success(`${t("sessionForm.successes.unpublish")}`);
      setIsUnpublishing(false);
      navigate(`/sessions?exhibitorId=${exhibitorId}`);
    } catch {
      toast.error(t("sessionForm.errors.unpublish"));
      setIsUnpublishing(false);
    }
  };

  const handlePhotoPictureLoad = (image: HTMLImageElement, index: number) => {
    profilePicture.current[index] = image;
  };

  /* function isHighlightDisabled(): boolean {
        if (session && session.highlight === true)
            return false;
        if (highlightCount < partnerStatut.highlightsTotal) {
            return false;
        }
        else {
            return true;
        }
    } */

  const isLimitedByDate = (date: any) => {
    var countAll = (allSessions as SessionDto[]).filter((s) =>
      s.startDateTimeSlot.startsWith(date.value.substring(0, 10))
    ).length;

    if (session) return countAll <= partnerStatut.sessionsPerDay;
    else return countAll < partnerStatut.sessionsPerDay;
  };

  const uploadPicture = async (file: File) => {
    var response = await uploadSessionPhoto(file.name, file.type);

    var blobDto = response.data as BlobDto;

    var string = blobDto.url.substring(0, blobDto.url.length - 1) + blobDto.sas;
    const blobServiceClient = new BlobServiceClient(string);

    const containerClient = blobServiceClient.getContainerClient(
      blobDto.container
    );

    const blobClient = containerClient.getBlockBlobClient(blobDto.blobName);

    // create blobClient for container
    const options = { blobHTTPHeaders: { blobContentType: file.type } };
    await blobClient.uploadData(file, options);

    return blobDto;
  };

  async function onChangeProfilePicture(
    file: File,
    index: number
  ): Promise<File> {
    try {
      var blob = await uploadPicture(file);
      setValue(`speakers[${index}].photo.name`, blob.blobName);
      return file;
    } catch {
      return file;
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {status === "Preview" ? (
        <h1>
          {t("common.viewSession")} - {t("sessionForm.waitingForApproval")}
        </h1>
      ) : (
        <h1>{t(session ? "common.editSession" : "common.addSession")}</h1>
      )}
      <Link
        to={`/sessions?exhibitorId=${exhibitorId}`}
        className="button gradient-vvt"
      >
        <i className="fas fa-arrow-left"></i>
        {t("common.back")}
      </Link>
      <div
        style={{
          pointerEvents: isPreview ? "none" : "auto",
        }}
      >
        <div className="notification information margin">
          <i className="fas fa-info"></i>
          <p>{t("sessionForm.disclaimer")}</p>
        </div>

        <div className="field">
          <label>{t("sessionForm.timeslots")}*:</label>
          <Controller
            control={control}
            name="date"
            render={({ field: { onChange, value } }) => (
              <Select
                value={value}
                onChange={(newValue) => {
                  onChange(newValue);
                  setContentLanguage(
                    newValue?.value.includes("2023-06-17") ? " - FR" : " - EN"
                  );
                }}
                options={daysOptions}
              />
            )}
            rules={{
              required: {
                value: true,
                message: t("common.errors.required"),
              },
              validate: isLimitedByDate,
            }}
          />
          {errors.date && errors.date.type === "required" && (
            <p className="formError">{t("common.errors.required")}</p>
          )}
          {errors.date && errors.date.type === "validate" && (
            <p className="formError">{t("sessionForm.errors.dateLimit")}</p>
          )}
        </div>

        <div className="field">
          <label>
            {t("sessionForm.title")}* <b>{contentLanguage}</b>:
          </label>
          <input
            className="input-long"
            type="text"
            {...register("title", {
              required: {
                value: true,
                message: t("common.errors.required"),
              },
            })}
            maxLength={80}
          />
          {errors.title && <p className="formError">{errors.title.message}</p>}
        </div>
        <form
          className="margin sessionform"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <div className="field">
            <label>{t("sessionForm.category")}*:</label>
            <Controller
              control={control}
              name="typologieDeSession"
              defaultValue={[]}
              render={({ field: { onChange, value } }) => (
                <Select
                  onChange={onChange}
                  value={value}
                  options={categoryOptions}
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: t("common.errors.required"),
                },
              }}
            />
            {errors.typologieDeSession && (
              <p className="formError">{errors.typologieDeSession.message}</p>
            )}
          </div>

          <div className="field" style={{ display: "none" }}>
            <label>{t("sessionForm.sessionFormat")}*:</label>
            <Controller
              control={control}
              name="sessionFormat"
              defaultValue={[]}
              render={({ field: { onChange, value } }) => (
                <Select
                  onChange={onChange}
                  value={value}
                  defaultValue="in-person"
                  options={sessionFormatOptions}
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: t("common.errors.required"),
                },
              }}
            />
            {errors.sessionFormat && (
              <p className="formError">{errors.sessionFormat.message}</p>
            )}
          </div>
          <div className="field">
            <label>
              {t("sessionForm.description")} {contentLanguage}*:
            </label>
            <textarea
              className="textarea"
              {...register("description", {
                required: {
                  value: true,
                  message: t("common.errors.required"),
                },
              })}
              style={{ minHeight: "6em" }}
              maxLength={300}
            ></textarea>
            {errors.description && (
              <p className="formError">{errors.description.message}</p>
            )}
          </div>

          <div
            className="flex-container"
            style={{ marginTop: 20, marginBottom: 20 }}
          >
            <TimePicker
              label={t("common.startDate")}
              value={startTimeSlot}
              onChange={(newValue) => {
                const d = newValue || new Date();
                setStartTimeSlot(d);
                if (endTimeSlot && d >= endTimeSlot) {
                  setEndTimeSlot(new Date(d.getTime() + 300000));
                }
              }}
              minTime={minTime}
              maxTime={maxTime}
              minutesStep={5}
              ampm={false}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant={"standard"}
                  required
                  style={{ marginRight: 40 }}
                />
              )}
            />
            <TimePicker
              label={t("common.endDate")}
              value={endTimeSlot}
              onChange={(newValue) => {
                const d = newValue || new Date();
                setEndTimeSlot(d);
                if (startTimeSlot && d <= startTimeSlot) {
                  setStartTimeSlot(new Date(d.getTime() - 300000));
                }
              }}
              minTime={startTimeSlot ?? minTime}
              maxTime={maxTime}
              minutesStep={5}
              ampm={false}
              renderInput={(params) => (
                <TextField {...params} variant={"standard"} required />
              )}
            />
          </div>
          {/* <div className="field">
                        <label>{t('sessionForm.thumbnail')}:</label>
                        <Controller
                            control={control}
                            name={`thumbnail`}
                            render={({
                                field: { value }
                            }) => (
                                <ProfilePicture
                                    onChange={onChangeThumbnailPicture}
                                    customClassName="thumbnailPicture"
                                    onImageLoad={handleThumbnailPictureLoad}
                                    value={value}
                                    src={value?.url ? value.url : thumbnailPlaceholder}
                                />
                            )}
                            rules={{
                                validate: {
                                    imageFormat: value => {
                                        return !value || !!value?.name || (value && value.type && value?.type.includes('image/')) || `${t('common.errors.invalidImageFile')}`;
                                    },
                                    imageDimensions: () => {
                                        const img = thumbnailPicture.current;
                                        return !img || (img && img.naturalWidth > 100 && img.naturalHeight > 100) || `${t('common.errors.invalidImageDimensions')}`;
                                    },
                                    imageSize: value => {
                                        return !value || !!value?.name || value.size < 2000000 || `${t('common.errors.invalidFileSize')}`;
                                    }
                                }
                            }}
                        />
                        {errors.thumbnail && (
                            <p className="formError">{errors.thumbnail.message}</p>
                        )}
                    </div> */}
          {/* <div className="field">
                        {
                            isHighlightDisabled() ?
                                (
                                    <Tooltip title={`${t('sessionForm.errors.highlightLimit')}`}>
                                        <div className='is-flex' style={{
                                            width: '10%'
                                        }}>
                                            <input
                                                className="checkbox"
                                                style={{
                                                    marginRight: '10px',
                                                }}
                                                type="checkbox"
                                                id="highlights"
                                                {...register('highlights')}
                                                disabled={isHighlightDisabled()}
                                            />
                                            <label htmlFor="highlights">{t('sessionForm.highlight')}</label>
                                        </div>
                                    </Tooltip>
                                ) :
                                (
                                    <div className='is-flex' style={{
                                        width: '10%'
                                    }}>
                                        <input
                                            className="checkbox"
                                            style={{
                                                marginRight: '10px',
                                            }}
                                            type="checkbox"
                                            id="highlights"
                                            {...register('highlights')}
                                            disabled={isHighlightDisabled()}
                                        />
                                        <label htmlFor="highlights">{t('sessionForm.highlight')}</label>
                                    </div>
                                )
                        }
                    </div> */}
          <div className="field">
            <label>
              {t("sessionForm.hashtags")} (max. {partnerStatut.hashtags}):
            </label>
            {!hashtagsOptions ? (
              <TailSpin
                color="#1d6ff7"
                height={20}
                width={20}
                wrapperClass="loader-left margin"
              />
            ) : (
              <Controller
                control={control}
                name="interestFilter"
                defaultValue={[]}
                render={({ field: { onChange, value } }) => (
                  <Select
                    isMulti
                    onChange={onChange}
                    value={value}
                    isOptionDisabled={(option, selectValue) => {
                      return value && value.length >= partnerStatut.hashtags;
                    }}
                    options={hashtagsOptions}
                  />
                )}
              />
            )}
          </div>

          <div className="field">
            <label>{t("sessionForm.exhibitors")}:</label>
            {!exhibitors ? (
              <TailSpin
                color="#1d6ff7"
                height={20}
                width={20}
                wrapperClass="loader-left margin"
              />
            ) : (
              <Controller
                control={control}
                name="exhibitors"
                defaultValue={[]}
                render={({ field: { onChange, value } }) => (
                  <Select
                    isMulti
                    onChange={onChange}
                    value={value}
                    isSearchable={true}
                    menuPlacement={"top"}
                    options={exhibitors
                      ?.filter((e) => e.id !== exhibitorId)
                      .map((e) => ({
                        value: e.id,
                        label: e.name,
                      }))}
                  />
                )}
              />
            )}
          </div>

          <div className="field">
            <div
              className="flex-container gap"
              style={{ marginTop: 20, alignItems: "baseline" }}
            >
              <label>{t("sessionForm.speakers")}:</label>
            </div>
            <div className="margin">
              {speakersFields.length === 0 && (
                <span>{t("sessionForm.noSpeaker")}</span>
              )}
              {speakersFields.map((field, i) => {
                const isMainSpeaker =
                  (field as SpeakerDto).isPartnerSpeaker === false;
                return (
                  <div key={field.id}>
                    <div className="is-flex gap align-items-start margin">
                      <div>
                        <label>{t("sessionForm.photo")} *</label>
                        <Controller
                          control={control}
                          key={`speakerPhoto${i}`}
                          render={({ field: { value } }) => (
                            <ProfilePicture
                              onChange={(f) => {
                                return onChangeProfilePicture(f, i);
                              }}
                              onImageLoad={(image) =>
                                handlePhotoPictureLoad(image, i)
                              }
                              value={value}
                              disabled={isMainSpeaker || status === "Preview"}
                              src={
                                value?.url ? value.url : thumbnailPlaceholder
                              }
                            />
                          )}
                          {...register(`speakers.${i}.photo`, {
                            required: {
                              value: !isMainSpeaker,
                              message: t("common.errors.required"),
                            },
                            validate: {
                              imageFormat: (value) => {
                                return (
                                  !value ||
                                  !!value?.name ||
                                  (value &&
                                    value.type &&
                                    value?.type.includes("image/")) ||
                                  `${t("common.errors.invalidImageFile")}`
                                );
                              },
                              imageDimensions: () => {
                                const img = profilePicture.current
                                  ? profilePicture.current[i]
                                  : undefined;
                                return (
                                  !img ||
                                  (img &&
                                    img.naturalWidth > 100 &&
                                    img.naturalHeight > 100) ||
                                  `${t("common.errors.invalidImageDimensions")}`
                                );
                              },
                              imageSize: (value) => {
                                return (
                                  !value ||
                                  !!value?.name ||
                                  value.size < 2000000 ||
                                  `${t("common.errors.invalidFileSize")}`
                                );
                              },
                            },
                          })}
                        />
                      </div>
                      <div
                        className="is-flex gap align-items-center flex-wrap"
                        style={{ flexGrow: 2 }}
                      >
                        <div>
                          <label>{t("sessionForm.civility")} *</label>
                          <Controller
                            control={control}
                            name={`speakers.${i}.civilityOption`}
                            render={({ field: { onChange, value } }) => (
                              <Select
                                value={value}
                                isDisabled={isMainSpeaker}
                                onChange={(newValue) => {
                                  onChange(newValue);
                                  setValue(
                                    `speakers.${i}.civility`,
                                    newValue.value
                                  );
                                }}
                                getOptionValue={(option) => option.value}
                                options={civilityOptions}
                              />
                            )}
                            rules={{
                              required: {
                                value: !isMainSpeaker,
                                message: t("common.errors.required"),
                              },
                            }}
                          />
                        </div>
                        <div>
                          <label>{t("sessionForm.lastName")} *</label>
                          <input
                            type="text"
                            className="input"
                            style={styleSpeakerInput}
                            disabled={isMainSpeaker}
                            placeholder={t("sessionForm.lastName")}
                            {...register(`speakers.${i}.lastName`, {
                              required: {
                                value: !isMainSpeaker,
                                message: t("common.errors.required"),
                              },
                            })}
                          />
                        </div>
                        <div>
                          <label>{t("sessionForm.firstName")} *</label>
                          <input
                            type="text"
                            className="input"
                            style={styleSpeakerInput}
                            disabled={isMainSpeaker}
                            placeholder={t("sessionForm.firstName")}
                            {...register(`speakers.${i}.firstName`, {
                              required: {
                                value: !isMainSpeaker,
                                message: t("common.errors.required"),
                              },
                            })}
                          />
                        </div>
                        <div>
                          <label>{t("sessionForm.mail")} (**)</label>
                          <input
                            type="email"
                            className="input"
                            style={styleSpeakerInput}
                            disabled={isMainSpeaker}
                            maxLength={150}
                            placeholder={t("sessionForm.mail")}
                            {...register(`speakers.${i}.mail`, {
                              required: {
                                value: false, // !isMainSpeaker,
                                message: t("common.errors.required"),
                              },
                              pattern: {
                                value:
                                  /^[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+$/gm,
                                message: t("common.errors.invalidEmail"),
                              },
                              validate: {
                                existingEmail: (value) => {
                                  if (
                                    value &&
                                    speakers &&
                                    (speakers as SpeakerDto[]).filter(
                                      (s, idx: number) =>
                                        idx < i && s.mail === value
                                    ).length > 0
                                  )
                                    return `${t(
                                      "common.errors.existingSpeakerEmail"
                                    )}`;
                                  return true;
                                },
                              },
                            })}
                          />
                        </div>
                        <div className="break"></div>
                        <div>
                          <label>{t("sessionForm.jobTitle")} *</label>
                          <input
                            type="text"
                            className="input"
                            style={styleSpeakerInput}
                            disabled={isMainSpeaker}
                            maxLength={80}
                            placeholder={t("sessionForm.jobTitle")}
                            {...register(`speakers.${i}.jobTitle`, {
                              required: {
                                value: !isMainSpeaker,
                                message: t("common.errors.required"),
                              },
                            })}
                          />
                        </div>
                        <div>
                          <label>{t("sessionForm.company")} *</label>
                          <input
                            type="text"
                            className="input"
                            style={styleSpeakerInput}
                            disabled={isMainSpeaker}
                            maxLength={80}
                            placeholder={t("sessionForm.company")}
                            {...register(`speakers.${i}.company`, {
                              required: {
                                value: !isMainSpeaker,
                                message: t("common.errors.required"),
                              },
                            })}
                          />
                        </div>
                        {errors.speakers?.[i] && (
                          <>
                            <div className="break"></div>
                            <div>
                              <ul>
                                {Object.entries(errors.speakers[i]).map(
                                  ([key, value]: any, i) => (
                                    <li key={i}>
                                      <p className="formError">
                                        {t(`sessionForm.errorListItem`, {
                                          field: t(`sessionForm.${key}`),
                                        })}{" "}
                                        : {value.message}
                                      </p>
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          </>
                        )}
                      </div>
                      {status !== "Preview" && (
                        <div style={{ marginTop: 34, textAlign: "right" }}>
                          <button
                            className="button gradient-vvt"
                            type="button"
                            disabled={isLoading || isPublishing || isSaving}
                            onClick={() => removeSpeaker(i)}
                          >
                            <i className="fas fa-trash"></i>
                            {t("sessionForm.removeSpeaker")}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
              {speakersFields.length > 0 && (
                <div
                  className="speaker-warning"
                  dangerouslySetInnerHTML={{
                    __html: t("sessionForm.warningSpeaker"),
                  }}
                ></div>
              )}
            </div>
            {isSubmitted && errors && (
              <div className="is-flex gap align-items-start margin">
                <p className="formError">{t("common.errors.formIncomplete")}</p>
              </div>
            )}
            {status !== "Preview" && (
              <div className="margin">
                <button
                  className="button gradient-vvt"
                  type="button"
                  disabled={isLoading || isPublishing || isSaving}
                  onClick={() => appendSpeaker(defaultValues.speakers[0])}
                >
                  {t("sessionForm.addSpeaker")}
                  <i className="fas fa-plus"></i>
                </button>
              </div>
            )}
          </div>

          {status !== "Preview" && (
            <>
              <div
                className="is-flex gap align-items-center margin"
                style={{ marginTop: "20px" }}
              >
                {(status === "Draft" || status === "Published") && (
                  <Button
                    type="submit"
                    className="button gradient-vvt"
                    label={t("common.save.title")}
                    icon={<i className="fas fa-save"></i>}
                    isLoading={isSaving}
                    disabled={isLoading || isPublishing}
                  />
                )}
                {status === "Draft" && (
                  <Button
                    type="button"
                    className="button gradient-vvt"
                    onClick={handlePublish}
                    label={t("common.saveAndPublish.title")}
                    icon={<i className="fas fa-paper-plane"></i>}
                    isLoading={isPublishing}
                    disabled={isLoading || isSaving}
                  />
                )}
                {status === "Published" && (
                  <Button
                    type="button"
                    className="button gradient-vvt"
                    onClick={handleUnpublish}
                    label={t("common.unpublish.title")}
                    icon={<i className="fas fa-calendar-times"></i>}
                    isLoading={isUnpublishing}
                    disabled={isLoading || isUnpublishing || isSaving}
                  />
                )}
              </div>
              <div className="is-flex smallgap align-items-center margin">
                <i className="fas fa-save"></i> {t("common.save.description")}
              </div>
              <div className="is-flex smallgap align-items-center margin">
                <i className="fas fa-paper-plane"></i>{" "}
                {t("common.saveAndPublish.description")}
              </div>
            </>
          )}
        </form>
      </div>
    </LocalizationProvider>
  );
};

export default SessionForm;
