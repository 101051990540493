import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { IWStartupDto } from '../../models/Dtos';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../basics/Button';
import { TailSpin } from 'react-loader-spinner';
import { getStartupDetails, setStartupAsChild, setStartupAsMaster, setStartupAsNotDuplicate } from '../../api/apis';

const MasterStartupsSelect = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [isFetchLoading, setIsFetchLoading] = useState<boolean>(true);
    const [startups, setStartups] = useState<IWStartupDto[]>();
    const eventId = searchParams.get('eventId');
    const customerId = searchParams.get('customerId');

    const handleBackClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        navigate(`/masterdashboard?customerId=${customerId}&eventId=${eventId}`);
    }, [navigate, eventId, customerId]);

    useEffect(() => {
        if (!startups) {
            const exIds = searchParams.get('ids')?.split(';');
            if (exIds) {
                // Call API to get all details
                const promises = exIds?.map((id) => getStartupDetails(id));
                Promise.all(promises)
                    .then((results) => {
                        setStartups(results.map(r => r.data));
                        setIsFetchLoading(false);
                    });
            }
            else {
                setStartups([]);
            }
        }
    }, [startups, searchParams]);

    const masterStartup = useMemo(() => {
        return startups?.find((s) => s.isMasterStartup);
    }, [startups]);

    const boBaseUrl = useMemo(() => {
        const eventId = searchParams.get('eventId');
        const customerId = searchParams.get('customerId');
        return `https://my.inwink.com/${customerId}/e/${eventId}/exhibitors`;
    }, [searchParams]);

    const handleMasterClick = useCallback((exhibitorId: string | undefined) => {
        if (startups && exhibitorId) {
            if (!masterStartup) {
                // Call API to set this exhibitor as master
                const currentEx = startups.find(s => s.id === exhibitorId);
                if (currentEx && currentEx.id) {
                    setStartupAsMaster(currentEx.id)
                        .then((res) => {
                            if (res.data) {
                                currentEx.isMasterStartup = true;
                                setStartups([currentEx, ...startups.filter(s => s.id !== exhibitorId)]);

                                toast.success(`Startup ${currentEx.name} définie comme master`);
                            } else {
                                toast.error('Impossible de définir comme master');
                            }
                        }).catch(() => {
                            // show error (there is already a master, should not happen)
                            toast.error('Impossible de définir comme master');
                        });
                }
            }
            else {
                // show error (there is already a master, should not happen)
                toast.error('Impossible de définir comme master');
            }
        }
    }, [startups, masterStartup]);

    const handleHideClick = useCallback((exhibitorId: string | undefined) => {
        if (startups && exhibitorId) {
            const currentExIdx = startups.findIndex(s => s.id === exhibitorId);
            if (currentExIdx >= 0) {
                const currentEx = startups[currentExIdx];
                if (!currentEx.isMasterStartup && currentEx.id) {
                    setStartupAsNotDuplicate(currentEx.id)
                        .then((res) => {
                            if (res.data) {
                                setStartups([...startups.slice(0, currentExIdx), ...startups.slice(currentExIdx + 1)]);
                                toast.success(`Startup ${currentEx.name} indiquée comme n'étant pas un doublon; elle ne remontera plus dans le suivi.`);
                            } else {
                                toast.error(`Impossible de marquer la startup ${currentEx.name} comme n'étant pas un doublon`);
                            }
                        }).catch(() => {
                            toast.error(`Impossible de marquer la startup ${currentEx.name} comme n'étant pas un doublon`);
                        });

                } else {
                    toast.error(`Impossible de marquer la startup ${currentEx.name} comme n'étant pas un doublon car elle est MASTER`);
                }
            }
            else {
                toast.error(`Impossible de marquer la startup comme n'étant pas un doublon car elle n'est pas dans la liste`);
            }
        }
    }, [startups]);

    const handleChildClick = useCallback((exhibitorId: string | undefined) => {
        if (startups && exhibitorId) {
            if (masterStartup && masterStartup.id) {
                // Call API to set this exhibitor as child
                const currentExIdx = startups.findIndex(s => s.id === exhibitorId);
                if (currentExIdx >= 0) {
                    const currentEx = startups[currentExIdx];
                    if (currentEx.id) {
                        setStartupAsChild(currentEx.id, masterStartup.id)
                            .then((res) => {
                                if (res.data) {
                                    currentEx.masterStartupId = masterStartup.id;
                                    setStartups([...startups.slice(0, currentExIdx), currentEx, ...startups.slice(currentExIdx + 1)]);
                                    toast.success(`Startup ${currentEx.name} définie comme enfant de ${masterStartup.name} (${masterStartup.id})`);
                                } else {
                                    toast.error('Impossible de définir comme enfant');
                                }
                            }).catch(() => {
                                toast.error('Impossible de définir comme enfant');
                            });
                    }
                }
            }
            else {
                // show error (no master, should not happen)
                toast.error('Impossible de définir comme enfant');
            }
        }
    }, [startups, masterStartup]);

    return (<div>
        <h1>Définition des startups master &amp; enfant</h1>
        {isFetchLoading ? (
            <TailSpin
                color="#1d6ff7"
                height={50}
                width={50}
                wrapperClass="loader margin"
            />) : (
            <>
                <table className="table masterstartups">
                    <thead>
                        <tr>
                            <th style={{ width: 200 }}>
                                <span>Name</span>
                            </th>
                            <th style={{ width: 60 }}>
                                <span>BO</span>
                            </th>
                            <th>
                                <span></span>
                            </th>
                            <th style={{ width: 120 }}>
                                <span>Status</span>
                            </th>
                            <th style={{ width: 60 }}>
                                <span></span>
                            </th>
                            <th style={{ width: 120 }}>
                                <span>Status modified</span>
                            </th>
                            <th style={{ width: 120 }}>
                                <span>Presence</span>
                            </th>
                            <th style={{ width: 300 }}>
                                <span>Contacts</span>
                            </th>
                            <th style={{ width: 180 }}>
                                <span>Id</span>
                            </th>
                            <th style={{ width: 180 }}>
                                <span>Master Id</span>
                            </th>
                            <th style={{ width: 200 }}>
                                <span>Actions</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {startups?.map(startup => (
                            <tr key={startup.id}>
                                <td>
                                    <a href={`${boBaseUrl}?entityid=${startup.id}`} rel="noopener noreferrer" target="_blank" style={{ marginRight: 10 }}>{startup.name}</a>
                                    {startup.isMasterStartup === true && (<i className="fas fa-trophy"></i>)}
                                    {startup.masterStartupId && (<i className="fas fa-child"></i>)}
                                    {startup.affiliatedTo && (<div> <i>- Parent: {startup.affiliatedTo.name}</i></div>)}
                                </td>
                                <td><a href={`${boBaseUrl}?entityid=${startup.id}`} rel="noopener noreferrer" target="_blank"><i className="fas fa-link"></i></a></td>
                                <td><span></span></td>
                                <td><span>{startup.status}</span></td>
                                <td><i className="fas fa-address-card" style={{ color: (startup.activitySector && startup.activitySector.length > 0) ? 'green' : 'red' }} title={(startup.activitySector && startup.activitySector.length > 0) ? 'Infos comm saisies' : 'Infos comm incomplètes'}></i></td>
                                <td><span>{startup.dateUpdatedStatus ? new Date(startup.dateUpdatedStatus).toLocaleDateString() : "-"}</span></td>
                                <td><span>{startup.presence === "online" ? "online" : startup.dayOfPresence?.map(d => (<span key={d}>{d} </span>))}</span></td>
                                <td>{startup.status !== 'Published' && (startup.exhibitorAccounts == null || startup.exhibitorAccounts.length === 0) ? startup.ag_Su_Contactmail : startup.exhibitorAccounts.map(a => (<div>{a.person.mail}</div>))}</td>
                                <td><span>{startup.id}</span></td>
                                <td><span>{startup.masterStartupId}</span></td>
                                <td className="startups-actions">
                                    {!masterStartup && !startup.masterStartupId && (
                                        <Button label="" title="Définir comme Master" type="button" onClick={() => handleMasterClick(startup.id)} icon={<i className="fas fa-trophy"></i>} />
                                    )}
                                    {masterStartup && startup.isMasterStartup !== true && !startup.masterStartupId && (
                                        <Button type="button" title="Définir comme enfant" onClick={() => handleChildClick(startup.id)} label="" icon={<i className="fas fa-child"></i>} />
                                    )}
                                    {!startup.masterStartupId && (
                                        <Button label="" title="Marquer comme n'étant pas un doublon" type="button" onClick={() => handleHideClick(startup.id)} icon={<i className="fas fa-eye-slash"></i>} />
                                    )}
                                </td>
                            </tr>)
                        )}
                    </tbody>
                </table>
                {!masterStartup ?
                    (<div style={{ marginTop: 20 }}>Il n'y a aucune startup marquée comme "Master" (<i className="fas fa-trophy"></i>). Commencez par définir celle-ci afin de pouvoir associer des startups "Enfant" <i className="fas fa-child"></i>.</div>) :
                    (<div style={{ marginTop: 20 }}>Vous pouvez définir des startups enfants (<i className="fas fa-child"></i>).</div>)
                }
                <div style={{ marginTop: 20 }}>Vous pouvez marquer des startups qui ne sont pas "Master" comme n'étant pas des doublons (<i className="fas fa-eye-slash"></i>).</div>
                <div style={{ marginTop: 20 }}>
                    <Button type="button" onClick={handleBackClick} label="Retour" />
                </div>
            </>)
        }
    </div>);
}

export default MasterStartupsSelect;