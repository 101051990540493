import { debounce } from 'lodash';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TailSpin } from 'react-loader-spinner';
import {
    Link,
    useNavigate,
    useSearchParams
} from 'react-router-dom';
import Select from 'react-select';
import { Column, useTable, usePagination } from 'react-table';
import { selectStyles } from '../../constants/styles';
import {
    CreateStartupRequestDto,
    StartupAgorizeDto,
} from '../../models/Dtos';
import { useBooleanState } from '../../utilities/hooks';
import Button from '../basics/Button';
import Modal from '../basics/Modal';
import { useDaysPresenceOptions } from '../../utilities/hooks';
import { toast } from 'react-toastify';
import { Source } from '../../enums/Source';
import { createStartup, getStartupsFromAgorize } from '../../api/apis';

const StartupImport = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const exhibitorId = searchParams.get('exhibitorId');
    const [startups, setStartups] = useState<StartupAgorizeDto[]>([]);
    const fetchIdRef = React.useRef(0);
    const [isFetchLoading, startFetchLoading, stopFetchLoading] = useBooleanState();
    const [isOpenPresenceModal, openPresenceModal, closePresenceModal] = useBooleanState();
    const daysPresenceOptions = useDaysPresenceOptions();
    const [startupPresence, setStartupPresence] = useState([daysPresenceOptions[0]]);
    const currentStartup = useRef<StartupAgorizeDto>();
    const navigate = useNavigate();

    const selectedAgorizeIds = useMemo(() => {
        return (searchParams.get('selectedAgorizeIds') || '').split(',');
    }, [searchParams]);

    const handleImportClick = useCallback((startup: StartupAgorizeDto) => {
        openPresenceModal();
        currentStartup.current = startup;
    }, []);

    const handleImportConfirm = async () => {
        if (!currentStartup.current || !exhibitorId) return;
        const startup = currentStartup.current as StartupAgorizeDto;
        const body: CreateStartupRequestDto = {
            socialReason: startup.name,
            parentExhibitorId: exhibitorId,
            source: Source.Agorize,
            sourceId: startup.id,
            daysPresences: startupPresence.map((dp: any) => dp.value),
            contact: {
                firstName: startup.contactFirstName,
                lastName: startup.contactLastName,
                mail: startup.contactEmail,
                country: startup.country,
                phoneNumber: startup.contactPhone
            }
        };

        try {
            await createStartup('save', body);
            toast.success(`${t(`dashboard.successes.agorizeImport`)} (${body.socialReason})`);
            navigate(`/startups?exhibitorId=${exhibitorId}`);
        } catch {
            toast.error('Erreur');
        }
    };

    const columns: Column<StartupAgorizeDto>[] = React.useMemo(
        () => [
            { accessor: 'id' },
            { accessor: 'contactFirstName' },
            { accessor: 'contactLastName' },
            { accessor: 'contactEmail' },
            {
                Header: `${t('common.name')}`,
                accessor: 'name',
                minWidth: 150,
                width: 'auto',
            },
            {
                Header: `${t('common.contactinfo')}`,
                Cell: (value: any) => (
                    <>{value.cell.row.values.contactLastName} {value.cell.row.values.contactFirstName}<br />
                        {value.cell.row.values.contactEmail}</>
                ),
                minWidth: 150,
                width: 'auto',
            },
            {
                Header: `${t('importAgorize.contactPhone')}`,
                accessor: 'contactPhone',
                minWidth: 200,
                width: 200,
            },
            {
                Header: `${t('importAgorize.country')}`,
                accessor: 'country',
                minWidth: 150,
                width: 200,
            },
            {
                id: 'actions',
                Cell: (value: any) => {
                    const rowValues = value.cell.row.values;
                    return (
                        <div className="text-center">
                            {selectedAgorizeIds.indexOf(rowValues.id.toString()) < 0 ?
                                (

                                    <Button
                                        label={t('importAgorize.import')}
                                        onClick={handleImportClick.bind(null, rowValues)}
                                        icon={
                                            <i className="fas fa-arrow-right"></i>
                                        }
                                    />
                                ) :
                                (<i className="fas fa-trophy"></i>)}
                        </div>
                    )
                },
                minWidth: 150,
                width: 150,
            }
        ],
        [
            t,
            handleImportClick,
            selectedAgorizeIds
        ]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data: startups,
            manualPagination: true,
            pageCount: -1,


            initialState: {
                pageIndex: 0,
                hiddenColumns: [
                    'id',
                    'contactFirstName',
                    'contactLastName',
                    'contactEmail'
                ]
            }
        },
        usePagination
    );

    const fetchData = useCallback(async (name: string, pageSize?: number, pageIndex?: number) => {
        const fetchId = ++fetchIdRef.current;

        startFetchLoading();

        if (fetchId === fetchIdRef.current && exhibitorId) {
            const result = await getStartupsFromAgorize(exhibitorId, name, pageSize, pageIndex)
            setStartups(result.data);
            stopFetchLoading();
        }
    }, [exhibitorId]);

    useEffect(() => {
        fetchData('', pageSize, pageIndex);
    }, [
        fetchData,
        exhibitorId,
        pageIndex,
        pageSize
    ]);

    const handleSearchChange = debounce((e: ChangeEvent<HTMLInputElement>) => {
        fetchData(e.target.value);
    }, 500);

    const handlePresenceChange = (value: any) => {
        setStartupPresence(value);
    };

    return (
        <>
            <h1>{t('importAgorize.title')}</h1>

            <Link to={`/startups?exhibitorId=${exhibitorId}`}>
                <i className="fas fa-arrow-left"></i>
                {t('common.back')}
            </Link>

            <div className="margin" role="search">
                <label htmlFor="search-input">{t('importAgorize.searchStartup')} : </label>
                <input
                    id="search-input"
                    type="text"
                    className="input"
                    onChange={handleSearchChange}
                />
            </div>

            <table className="table margin agorize" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps({
                                    style: { minWidth: column.minWidth, width: column.width }
                                })}>
                                    <span>{column.render('Header')}</span>
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                {isFetchLoading ? (
                    <tbody>
                        <tr>
                            <td colSpan={7}>
                                <TailSpin
                                    color="#1d6ff7"
                                    height={50}
                                    width={50}
                                    wrapperClass="loader margin"
                                />
                            </td>
                        </tr>
                    </tbody>
                ) : startups.length > 0 ? (
                    <tbody {...getTableBodyProps()}>
                        {page.map(row => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} className={(selectedAgorizeIds.indexOf(row.values.id.toString()) >= 0 ? 'agorize-selected' : '')}>
                                    {row.cells.map(cell => (
                                        <td {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </td>
                                    ))}
                                </tr>
                            )
                        })}
                    </tbody>
                ) : (
                    <tbody>
                        <tr>
                            <td colSpan={7}>
                                <p className="text-center padding">
                                    {t('dashboard.noStartups')}
                                </p>
                            </td>
                        </tr>
                    </tbody>
                )}
            </table>

            <div className="pagination margin">
                <Button
                    label=""
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                    icon={
                        <i className="fas fa-angle-double-left"></i>
                    }
                />
                <Button
                    label=""
                    onClick={previousPage}
                    disabled={!canPreviousPage}
                    icon={
                        <i className="fas fa-angle-left"></i>
                    }
                />
                <Button
                    label=""
                    onClick={nextPage}
                    disabled={!canNextPage}
                    icon={
                        <i className="fas fa-angle-right"></i>
                    }
                />
                <Button
                    label=""
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                    icon={
                        <i className="fas fa-angle-double-right"></i>
                    }
                />
                <label>
                    {t('importAgorize.goToPage')} :
                    <input
                        type="number"
                        className="input"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(page)
                        }}
                        style={{ width: '100px' }}
                    />
                </label>

                <Select
                    value={{
                        label: t(`importAgorize.showPages`, { pageSize }),
                        value: pageSize,
                    }}
                    onChange={value => {
                        setPageSize(Number(value?.value));
                    }}
                    options={[5, 10, 20, 30, 40, 50].map(pageSize => ({
                        label: `Show ${pageSize}`,
                        value: pageSize,
                    }))}
                    styles={selectStyles}
                />
            </div>

            {isOpenPresenceModal && (
                <Modal onClose={closePresenceModal}>
                    <form>
                        <label>
                            {t('importAgorize.daysPresence')} :
                        </label>
                        <div className="presence-warning">{t('startupForm.presenceDescription')}</div>
                        <Select
                            isMulti
                            onChange={(newValues) => {
                                if (newValues.findIndex((v: any) => v.value === "online" || v.option.value === "online") < 0) {
                                    newValues = [daysPresenceOptions[0], ...newValues];
                                    
                                    toast.error(t(`startupForm.errors.alwaysOnline`));
                                }
                                handlePresenceChange(newValues);
                            }}
                            value={startupPresence}
                            styles={selectStyles}
                            options={daysPresenceOptions}
                        />
                        <div className="buttons">
                            <Button
                                label={t('common.cancel')}
                                onClick={closePresenceModal}
                                icon={
                                    <i className="fas fa-times"></i>
                                }
                            />
                            <Button
                                label={t('importAgorize.import')}
                                onClick={handleImportConfirm}
                                icon={
                                    <i className="fas fa-arrow-right"></i>
                                }
                            />
                        </div>
                    </form>
                </Modal>
            )}
        </>
    );
};

export default StartupImport;