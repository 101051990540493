import axios from "axios";
import {
  BlobDto,
  CreateStartupRequestDto,
  FieldValueListItem,
  IRequestContactInfo,
  IWExhibitorBasicInfoDto,
  IWExhibitorDto,
  IWSimilarStartupGroupDto,
  IWStartupDto,
  OfficeMeetingDto,
  PartnerStartupsKpiDto,
  RoomDto,
  SSoUrlDto,
  SessionDto,
  SpeakerWithPhotoDto,
  StartupAgorizeDto,
  StartupPartnerDto,
  UpdateStartupDto,
} from "../models/Dtos";

const baseApiPath = "/vvtapi";

export const getSimilarStartups = async () => {
  return axios.get<IWSimilarStartupGroupDto[]>(
    `${baseApiPath}/startups/similar`
  );
};

export const getStartupDetails = async (exhibitorId: string) => {
  return axios.get<IWStartupDto>(
    `${baseApiPath}/startups/details/${encodeURIComponent(exhibitorId)}`
  );
};

export const getStartupChildren = async (exhibitorId: string) => {
  return axios.get<StartupPartnerDto[]>(
    `${baseApiPath}/startups/exhibitor/${encodeURIComponent(
      exhibitorId
    )}/children`
  );
};

export const getStartupAffiliatedTo = async (exhibitorId: string) => {
  return axios.get<StartupPartnerDto[]>(
    `${baseApiPath}/startups/exhibitor/${encodeURIComponent(
      exhibitorId
    )}/affiliatedto`
  );
};

export const setStartupAsMaster = async (exhibitorId: string) => {
  return axios.put<boolean>(
    `${baseApiPath}/startups/master/${encodeURIComponent(exhibitorId)}`
  );
};

export const setStartupAsNotDuplicate = async (exhibitorId: string) => {
  return axios.put<boolean>(
    `${baseApiPath}/startups/notduplicate/${encodeURIComponent(exhibitorId)}`
  );
};

export const setStartupAsChild = async (
  exhibitorId: string,
  masterId: string
) => {
  return axios.put<boolean>(
    `${baseApiPath}/startups/child/${encodeURIComponent(
      exhibitorId
    )}/master/${encodeURIComponent(masterId)}`
  );
};

export const getStartupsFromAgorize = async (
  exhibitorId: string,
  name: string,
  pageSize?: number,
  pageIndex?: number
) => {
  let apiUrl = `${baseApiPath}/startups/agorize/exhibitor/${encodeURIComponent(
    exhibitorId
  )}?name=${name}`;
  if (pageSize !== undefined) apiUrl += `&pageSize=${pageSize}`;
  if (pageIndex !== undefined) apiUrl += `&pageNumber=${pageIndex}`;

  return axios.get<StartupAgorizeDto[]>(apiUrl);
};

export const createStartup = async (
  operationName: "save" | "publish",
  data: CreateStartupRequestDto
) => {
  return axios.post<StartupPartnerDto>(
    `${baseApiPath}/startups/create/${operationName}`,
    data
  );
};

export const updateStartup = async (
  exhibitorId: string,
  operationName: "save" | "publish",
  data: UpdateStartupDto
) => {
  return axios.put(
    `${baseApiPath}/startups/${encodeURIComponent(
      exhibitorId
    )}/${operationName}`,
    data
  );
};

export const changeStartupContact = async (
  exhibitorId: string,
  data: IRequestContactInfo
) => {
  return axios.post(
    `${baseApiPath}/startups/changecontact/${encodeURIComponent(exhibitorId)}`,
    data
  );
};

export const cancelStartup = async (exhibitorId: string) => {
  return axios.post(
    `${baseApiPath}/startups/cancel/${encodeURIComponent(exhibitorId)}`
  );
};

export const deleteStartup = async (exhibitorId: string) => {
  return axios.delete(
    `${baseApiPath}/startups/${encodeURIComponent(exhibitorId)}`
  );
};

export const getSessionInterests = async () => {
  return axios.get<FieldValueListItem[]>(`${baseApiPath}/session/interests`);
};

export const getPublishedStartups = async () => {
  return axios.get<IWExhibitorBasicInfoDto[]>(
    `${baseApiPath}/startups/published`
  );
};

export const createSession = async (
  exhibitorId: string,
  session: SessionDto
) => {
  return axios.post(
    `${baseApiPath}/session/exhibitor/${encodeURIComponent(
      exhibitorId
    )}/create`,
    session
  );
};

export const updateSession = async (
  exhibitorId: string,
  session: SessionDto
) => {
  return axios.put(
    `${baseApiPath}/session/exhibitor/${encodeURIComponent(
      exhibitorId
    )}/update`,
    session
  );
};

export const uploadSessionPhoto = async (name: string, type: string) => {
  return axios.post(`${baseApiPath}/session/upload/photo`, {
    name,
    type,
  });
};

export const uploadSpeakerPhoto = async (
  name: string,
  type: string,
  token: string
) => {
  return axios.post(
    `${baseApiPath}/speaker/upload/photo`,
    {
      name,
      type,
    },
    {
      headers: {
        "x-auth": token,
      },
    }
  );
};

export const updateSpeakerPhoto = async (
  speakerId: string,
  blob: BlobDto,
  token: string
) => {
  return axios.patch<SpeakerWithPhotoDto>(
    `${baseApiPath}/speaker/${speakerId}`,
    blob,
    {
      headers: {
        "x-auth": token,
      },
    }
  );
};

export const getSpeakersWithPhoto = async (filter: "official" | "partner", token: string) => {
  return axios.get<SpeakerWithPhotoDto[]>(`${baseApiPath}/speaker/${filter}`, {
    headers: {
      "x-auth": token,
    },
  });
};

export const getImage = async (imgUrl: string, token: string) => {
  return axios.get(imgUrl, {
    responseType: "blob",
    timeout: 30000,
    headers: {
      "x-auth": token,
    },
  });
};
export const unpublishSession = async (
  exhibitorId: string,
  sessionId: string
) => {
  return axios.get(
    `${baseApiPath}/session/${sessionId}/exhibitor/${encodeURIComponent(
      exhibitorId
    )}/unpublish`
  );
};

export const deleteSession = async (exhibitorId: string, sessionId: string) => {
  return axios.delete(
    `${baseApiPath}/session/${sessionId}/exhibitor/${encodeURIComponent(
      exhibitorId
    )}/delete`
  );
};
export const getSessions = async (exhibitorId: string) => {
  return axios.get<SessionDto[]>(
    `${baseApiPath}/session/exhibitor/${encodeURIComponent(exhibitorId)}/list`
  );
};

export const getExhibitor = async (exhibitorId: string) => {
  return axios.get<IWExhibitorDto>(
    `${baseApiPath}/startups/${encodeURIComponent(exhibitorId)}`
  );
};

export const getBadges = async (exhibitorId: string, personId: string) => {
  return axios.get<SSoUrlDto[]>(
    `${baseApiPath}/mediactive/badges/${encodeURIComponent(
      exhibitorId
    )}/${encodeURIComponent(personId)}`
  );
};

export const getInvitations = async (exhibitorId: string, personId: string) => {
  return axios.get<SSoUrlDto[]>(
    `${baseApiPath}/mediactive/invitations/${encodeURIComponent(
      exhibitorId
    )}/${encodeURIComponent(personId)}`
  );
};

export const getOfficeHoursSlots = async (location: string) => {
  return axios.get<RoomDto[]>(`${baseApiPath}/officehours/slots/${location}`);
};

export const getOfficeHoursBookedSlots = async (token: string) => {
  return axios.get<OfficeMeetingDto[]>(
    `${baseApiPath}/officehours/bookedslots`,
    { params: { t: token } }
  );
};

export const bookOfficeHoursSlot = async (
  roomId: string,
  slotId: string,
  token: string
) => {
  return axios.post<OfficeMeetingDto>(
    `${baseApiPath}/officehours/bookslot?t=${token}`,
    {
      roomId,
      slotId,
    }
  );
};

export const releaseOfficeHoursSlot = async (
  meetingId: string,
  slotId: string,
  token: string
) => {
  return axios.post<OfficeMeetingDto>(
    `${baseApiPath}/officehours/releaseslot?t=${token}`,
    {
      meetingId,
      slotId,
    }
  );
};

export const getStartupsKpi = async () => {
  return axios.get<PartnerStartupsKpiDto[]>(
    `${baseApiPath}/startups/exhibitor/startupskpi`
  );
};
