import React, {
    MouseEvent, ReactElement,
} from 'react';
import { TailSpin } from 'react-loader-spinner';

interface ButtonProps {
    label: string;
    type?: 'button' | 'submit' | 'reset';
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
    icon?: ReactElement;
    isLoading?: boolean;
    disabled?: boolean;
    title?: string;
    className?: string;
}

const Button = ({
    label,
    type = 'button',
    onClick,
    icon,
    isLoading,
    disabled,
    title,
    className = "button"
}: ButtonProps) => {
    return (
        <button
            className={className}
            type={type}
            onClick={onClick}
            disabled={disabled || isLoading}
        >
            {isLoading && (
                <TailSpin
                    color="#FFF"
                    height={15}
                    width={15}
                    wrapperClass="loader"
                />
            )}
            {icon}
            {label}
        </button>
    );
};

export default Button;