import React from 'react';
import './App.css';
import StartupsDashboard from './components/startupsDasbhoard/StartupsDashboard';
import MasterStartupsDashboard from './components/masterStartupsDashboard/MasterStartupsDashboard';
import MasterStartupsSelect from './components/masterStartupsSelect/MasterStartupsSelect';
import StartupForm from './components/startupsDasbhoard/StartupForm';
import StartupRequestChange from './components/startupsDasbhoard/StartupRequestChange';
import StartupImport from './components/startupsDasbhoard/StartupImport';
import SessionsList from './components/sessions/SessionsList';
import SessionForm from './components/sessions/SessionForm';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { ToastContainer, Zoom } from 'react-toastify';
import StartupsOverviewDashboard from './components/startupsOverviewDashboard/StartupsOverviewDashboard';
import OfficeHours from './components/officeHours/OfficeHours';
import Badges from './components/mediactive/Badges';
import Invitations from './components/mediactive/Invitations';
import SpeakersList from './components/speakersDashboard/SpeakersList';

function App() {
  return (
    <>
      <BrowserRouter basename={(document.location.pathname.startsWith('/vvtcust') ? '/vvtcust' : undefined)}>
          <div className="page">
              <Routes>
                  <Route path="startups" element={<StartupsDashboard />} />
                  <Route path="startup/form" element={<StartupForm />} />
                  <Route path="startup/requestchange" element={<StartupRequestChange />} />
                  <Route path="startups/import" element={<StartupImport />} />
                  <Route path="speakers-official" element={<SpeakersList filter={"official"} />} />
                  <Route path="speakers-partners" element={<SpeakersList filter={"partner"} />} />
                  <Route path="sessions" element={<SessionsList />} />
                  <Route path="session/form" element={<SessionForm />} />
                  <Route path="masterdashboard" element={<MasterStartupsDashboard />} />
                  <Route path="master/select" element={<MasterStartupsSelect />} />
                  <Route path="startupskpis" element={<StartupsOverviewDashboard />} />
                  <Route path="officehours-investors" element={<OfficeHours location='investorlounge' />} />
                  <Route path="officehours-founders" element={<OfficeHours location='founderlounge' />} />
                  <Route path="my/passes" element={<Badges />} />
                  <Route path="my/invitations" element={<Invitations />} />
              </Routes>
          </div>
      </BrowserRouter>
      <ToastContainer
          position="top-center"
          hideProgressBar
          autoClose={3000}
          transition={Zoom}
      />
    </>
  );
}

export default App;
