import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OfficeMeetingDto, RoomDto } from '../../models/Dtos';
import { useSearchParams } from 'react-router-dom';
import Button from '../basics/Button';
import { TailSpin } from 'react-loader-spinner';
import Select from 'react-select';
import { Card, CardActions, CardContent } from '@mui/material';
import { toast } from 'react-toastify';
import { bookOfficeHoursSlot, getOfficeHoursBookedSlots, getOfficeHoursSlots, releaseOfficeHoursSlot } from '../../api/apis';

interface OfficeHoursProps {
    location: 'investorlounge' | 'founderlounge';
}

const OfficeHours = (props: OfficeHoursProps) => {
    const { location } = props;
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const token = searchParams.get('t');
    const [isFetchLoading, setIsFetchLoading] = useState<boolean>(true);
    const [isUpdatingSlot, setIsUpdatingSlot] = useState<boolean>(false);
    const [selectedDate, setSelectedDate] = useState<string>();
    const [rooms, setRooms] = useState<RoomDto[]>();
    const [meetings, setMeetings] = useState<OfficeMeetingDto[]>();

    useEffect(() => {
        if (!rooms && token) {
            var p = [
                getOfficeHoursSlots(location),
                getOfficeHoursBookedSlots(token)
            ];

            Promise.all(p)
                .then((results) => {
                    setRooms(results[0].data as RoomDto[]);
                    setMeetings(results[1].data as OfficeMeetingDto[]);
                })
                .catch(() => {
                    setRooms([]);
                    setMeetings([]);
                }).finally(() => {
                    setIsFetchLoading(false);
                });
        }
    }, [rooms, token, location]);

    const bookRoomSlot = useCallback((roomId: string, slotId: string, roomName: string, timeSlot: string) => {
        if (meetings && token && !meetings.find(m => m.timeslotId === slotId)) {
            setIsUpdatingSlot(true);
            bookOfficeHoursSlot(roomId, slotId, token)
                .then((res) => {
                    setMeetings([...meetings, res.data]);

                    toast.success(`${t('officeHours.successes.booked')} ${roomName} ${timeSlot}`);
                })
                .catch(() => {
                    toast.error(`${t('officeHours.errors.cannotBook')}`);
                })
                .finally(() => setIsUpdatingSlot(false));
        }
    }, [meetings, token, t]);

    const releaseRoomSlot = useCallback((meetingId: string, slotId: string, roomName: string, timeSlot: string) => {
        if (meetings && token && meetings.find(m => m.timeslotId === slotId)?.mine === true) {
            setIsUpdatingSlot(true);
            releaseOfficeHoursSlot(meetingId, slotId, token)
                .then((res) => {
                    setMeetings([...meetings.filter(m => m.id !== meetingId)]);
                    toast.success(`${t('officeHours.successes.released')} ${roomName} ${timeSlot}`);
                })
                .catch(() => {
                    toast.error(`${t('officeHours.errors.cannotRelease')}`);
                })
                .finally(() => setIsUpdatingSlot(false));
        }
    }, [meetings, token, t]);

    const availableDateOptions = useMemo(() => {
        return [
            { value: "2024-05-22", label: t("common.days2024.wed") },
            { value: "2024-05-23", label: t("common.days2024.thu") },
            { value: "2024-05-24", label: t("common.days2024.fri") },
        ];
    }, [t]);

    if (!token)
        return <div>Not authorized</div>

    return (<div className="office-hours">     
        {(isFetchLoading || !rooms) ? (
            <TailSpin
                color="#1d6ff7"
                height={50}
                width={50}
                wrapperClass="loader margin"
            />) : (
            <div>
                <h2>{location === 'founderlounge' ? 'Founders Lounge' : 'Investors Lounge' }</h2>
                <div style={{ marginBottom: 20, width: '300px' }}>
                    <Select
                        placeholder={t("officeHours.selectDate.placeholder")}
                        onChange={(newValue) => setSelectedDate(newValue?.value)}
                        options={availableDateOptions} />
                </div>
                {selectedDate && (
                    <div className="booking-slots">
                        {rooms.map(r => {
                            const timeslotGroup = r.groupedTimeSlots.find(grp => grp.date === selectedDate);
                            const roomShortName = r.name.replace('Office Hours - ', '').replace('Founders Lounge - ', '').replace('Investors Lounge - ', '');

                            return (<div style={{ width: 140, paddingRight: 5, borderRight: '1px solid #F0F0F0' }}>
                                <div style={{ textAlign: 'center', marginBottom: 10 }}><b>{roomShortName}</b></div>
                                <div className="room-slots">
                                    {timeslotGroup?.timeSlots.map(ts => {
                                        const meeting = meetings?.find(m => m.timeslotId === ts.id);
                                        const timeSlotTxt = `${ts.startDate.substring(11, 16)} - ${ts.endDate.substring(11, 16)}`;

                                        return (<Card sx={{ maxWidth: '100%' }} style={{ marginTop: 10 }}>
                                            <CardContent style={{ textAlign: 'center' }}>
                                                <b>{timeSlotTxt}</b>
                                            </CardContent>
                                            <CardActions style={{ justifyContent: 'center' }}>
                                                {meeting && meeting.mine && (<Button type="button" label={t("officeHours.releaseSlot")} icon={<i className="fas fa-calendar-times" />} disabled={isUpdatingSlot} onClick={() => releaseRoomSlot(meeting.id, ts.id, roomShortName, timeSlotTxt)} />)}
                                                {meeting && !meeting.mine && (<Button type="button" label={t("officeHours.bookedSlot")} disabled={true} className="button gradient-vvt" />)}
                                                {!meeting && (<Button type="button" label={t("officeHours.bookSlot")} icon={<i className="fas fa-calendar-check" />} className="button gradient-vvt" disabled={isUpdatingSlot} onClick={() => bookRoomSlot(r.id, ts.id, roomShortName, timeSlotTxt)} />)}
                                            </CardActions>
                                        </Card>);
                                    })}
                                </div>
                            </div>);
                        })}
                    </div>
                )}
            </div>
        )}
    </div>);
};

export default OfficeHours;