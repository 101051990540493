import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useBooleanState = (
    defaultValue: boolean = false
): [boolean, () => void, () => void] => {
    const [value, setValue] = useState(defaultValue);
    return [
        value,
        () => setValue(true),
        () => setValue(false)
    ];
};

const timeslots = (day: {label: string; value: string;}) => {
    const timeslots = [];
    for(let i = 9; i < 18; i++) {
        timeslots.push({
            label: `${day.label} ${i}:00 - ${i + 1}:00`,
            value: `${day.value}-${i}:00-${i + 1}:00`
        });
    }
    return timeslots;
};

export const useDaysPresenceOptions = ({useTimeslots} = {useTimeslots: false}) => {
    const {t} = useTranslation();
    const days = [
        { value: 'wed', label: t('common.daysPresence.wed') },
        { value: 'thu', label: t('common.daysPresence.thu') },
        { value: 'fri', label: t('common.daysPresence.fri') },
        { value: 'sat', label: t('common.daysPresence.sat') }
    ];

    if(useTimeslots) {
        return days.map(d => ({
            label: d.label,
            options: timeslots(d)
        }))
    }

    return [
        { value: 'online', label: t('common.daysPresence.online') },
        ...days
    ];
};