import React, { BaseSyntheticEvent, useEffect, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import countries from '../../data/countries.json';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import { selectW350Styles, selectW720Styles } from '../../constants/styles';
import {
    CreateStartupRequestDto,
    UpdateStartupDto,
    IStartupFormState
} from '../../models/Dtos';
import { getPresenceOptionsFromDto } from '../../utilities/utilities';
import { useBooleanState, useDaysPresenceOptions } from '../../utilities/hooks';
import { toast } from 'react-toastify';
import Button from '../basics/Button';
import { TailSpin } from 'react-loader-spinner';
import { Source } from '../../enums/Source';
import { createStartup, updateStartup } from '../../api/apis';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

const StartupForm = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const exhibitorId = searchParams.get('exhibitorId');
    const canPublish = searchParams.get('canPublish') === '0' ? false : true;
    const canSave = searchParams.get('canSave') === '0' ? false : true;
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        setValue
    } = useForm();
    const createdStartupId = useRef<string>('');
    const location = useLocation();
    const startupFormState = (location.state as IStartupFormState);
    const disabledFields = 
        startupFormState.startup && (
            startupFormState.startup.spSource === 'Agorize' ||
            (startupFormState.startup.spStatus !== 'Draft' || !!startupFormState.startup.spExhibitorId)
        );
    const navigate = useNavigate();
    const daysPresenceOptions = useDaysPresenceOptions();
    const [isLoading, startLoading, stopLoading] = useBooleanState();

    useEffect(() => {
        if (!startupFormState.startup) return;

        const su = startupFormState.startup;
        setValue('socialReason', su.spName);
        setValue(
            'daysPresences',
            getPresenceOptionsFromDto(su).map(d => ({
                ...d,
                label: t(`common.daysPresence.${d.label}`)
            }))
        );        
        setValue('socialReason', su.spName);
        setValue('contact.lastName', su.spContactLastName);
        setValue('contact.firstName', su.spContactFirstName);
        setValue('contact.mail', su.spContactEmail);
        setValue('contact.phoneNumber', su.spContactPhone);
        setValue('contact.country', su.spCountry ? { label: su.spCountry, value: su.spCountry } : undefined);        
    }, [
        startupFormState.startup,
        setValue,
        t
    ]);

    const onSubmit = async (data: any, e?: BaseSyntheticEvent, publish = false) => {
        startLoading();

        const operationName = publish ? "publish" : "save";

        try {
            // Update existing startup
            if (startupFormState.startup) {
                const su = startupFormState.startup;
                const body: UpdateStartupDto = {
                    contact: {
                        firstName: data.contact.firstName || su.spContactFirstName,
                        lastName: data.contact.lastName || su.spContactLastName,
                        mail: data.contact.mail || su.spContactEmail,
                        phoneNumber: data.contact.phoneNumber || su.spContactPhone,
                        country: data.contact.country.value || su.spCountry,
                    },
                    socialReason: data.socialReason || su.spName,
                    daysPresences: []
                };

                if (data.daysPresences.length) {
                    body.daysPresences = data.daysPresences.map((dp: any) => dp.value);
                } else {
                    body.daysPresences = [data.daysPresences.value];
                }

                try {
                    await updateStartup(startupFormState.startup.spId, operationName, body);
                    toast.success(`${t(`startupForm.successes.edit.${operationName}`)} (${startupFormState.startup.spName})`);
                    createdStartupId.current = startupFormState.startup.spId;

                    navigate(`/startups?exhibitorId=${exhibitorId}`);
                } catch {
                    toast.error(t(`startupForm.errors.edit.${operationName}`));
                } finally {
                    stopLoading();
                }
            } else {
                // Create new startup
                const body: CreateStartupRequestDto = {
                    ...data,
                    parentExhibitorId: exhibitorId,
                    source: Source.Manual,
                    contact: {
                        ...data.contact,
                        country: data.contact.country.value,
                    }
                };

                if (data.daysPresences.length) {
                    body.daysPresences = data.daysPresences.map((dp: any) => dp.value);
                } else {
                    body.daysPresences = [data.daysPresences.value];
                }

                try {
                    const result = await createStartup(operationName, body);
                    createdStartupId.current = result.data.spId;
                    toast.success(`${t(`startupForm.successes.create.${operationName}`)} (${result.data.spName})`);

                    navigate(`/startups?exhibitorId=${exhibitorId}`);
                } catch {
                    toast.error(t(`startupForm.errors.create.${operationName}`));
                } finally {
                    stopLoading();
                }
            }
        }
        catch (e) {
            toast.error('error');
            stopLoading();
        }
    };

    const handleSaveAndPublish = async () => {
        await handleSubmit((data, e) => onSubmit(data, e, true))();
    };

    return (
        <>
            {startupFormState.startup ? (
                <h1>
                    {t('common.editStartup')} - {startupFormState.startup.spName}
                </h1>
            ) : (
                <h1>
                    {t('common.addStartup')}
                </h1>
            )}
            <Link to={`/startups?exhibitorId=${exhibitorId}`} className="button gradient-vvt">
                <i className="fas fa-arrow-left"></i>
                {t('common.back')}
            </Link>

            <form onSubmit={handleSubmit(onSubmit)} className="margin" noValidate>
                <div className="field">
                    <label htmlFor="socialReason">
                        {t('startupForm.companyName')}* :
                    </label>
                    <input
                        className="input w-720"
                        type="text"
                        {...register('socialReason', {
                            disabled: disabledFields,
                            required: {
                                value: true,
                                message: t('common.errors.required')
                            },
                            validate: {
                                noExistingName: value => {
                                    if (!startupFormState.startup && startupFormState.existingStartupNames && value && startupFormState.existingStartupNames.indexOf(value.toLowerCase()) >= 0) {
                                        return `${t('common.errors.existingStartupWithSameName')}`;
                                    }
                                    return true;
                                }
                            }
                        })}
                    />
                    {errors.socialReason && (
                        <div className="formError">
                            {errors.socialReason.message}
                        </div>
                    )}
                </div>

                <div className="field">
                    <label>{t('startupForm.daysPresence')}* :</label>
                    <div className="presence-warning">{t('startupForm.presenceDescription')}</div>
                    <Controller
                        control={control}
                        name="daysPresences"
                        defaultValue={daysPresenceOptions[0]}
                        render={({
                            field: { onChange, value }
                        }) => (
                            <Select
                                isMulti
                                onChange={(newValues) => {
                                    if (newValues.findIndex((v: { value: string, text: string }) => v.value === "online") < 0) {
                                        newValues = [daysPresenceOptions[0], ...newValues];

                                        toast.error(t(`startupForm.errors.alwaysOnline`));
                                    }
                                    onChange(newValues);
                                }}
                                value={value}
                                styles={selectW720Styles}
                                options={daysPresenceOptions}
                            />
                        )}
                        rules={{
                            required: {
                                value: true,
                                message: t('common.errors.required')
                            }
                        }}
                    />
                    {errors.daysPresences && (
                        <div className="formError">
                            {errors.daysPresences.message}
                        </div>
                    )}
                </div>

                <div className="is-flex gap">
                    <div className="field">
                        <label>
                            {t('startupForm.country')}* :
                        </label>
                        {disabledFields ? (
                            <Select
                                isDisabled
                                defaultValue={{
                                    label: countries.find(c => c === startupFormState.startup?.spCountry),
                                    value: startupFormState.startup?.spCountry
                                }}
                                styles={selectW350Styles}
                            />
                        ) : (
                            <Controller
                                control={control}
                                name="contact.country"
                                render={({
                                    field: { onChange, value }
                                }) => (
                                    <Select
                                        onChange={onChange}
                                        value={value}
                                        styles={selectW350Styles}
                                        options={
                                            countries.map(c => ({
                                                value: c,
                                                label: c
                                            }))
                                        }
                                    />
                                )}
                                rules={{
                                    required: {
                                        value: true,
                                        message: t('common.errors.required')
                                    }
                                }}
                            />
                        )}
                        {errors.contact?.country && (
                            <div className="formError">
                                {errors.contact.country.message}
                            </div>
                        )}
                    </div>
                </div>

                <fieldset className="margin">
                    <label>{t('startupForm.operationalContact')}</label>
                    <div className="presence-warning">{t('startupForm.operationalContactDescription')}</div>

                    <div className="is-flex gap">
                        <div className="field">
                            <label htmlFor="lastName">
                                {t('startupForm.lastName')}* :
                            </label>
                            <input
                                className="input w-350"
                                type="text"
                                {...register('contact.lastName', {
                                    disabled: disabledFields,
                                    required: {
                                        value: true,
                                        message: t('common.errors.required')
                                    }
                                })}
                            />
                            {errors.contact?.lastName && (
                                <div className="formError">
                                    {errors.contact.lastName.message}
                                </div>
                            )}
                        </div>

                        <div className="field">
                            <label htmlFor="firstName">
                                {t('startupForm.firstName')}* :
                            </label>
                            <input
                                className="input w-350"
                                type="text"
                                {...register('contact.firstName', {
                                    disabled: disabledFields,
                                    required: {
                                        value: true,
                                        message: t('common.errors.required')
                                    }
                                })}
                            />
                            {errors.contact?.firstName && (
                                <div className="formError">
                                    {errors.contact.firstName.message}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="is-flex gap">
                        <div className="field">
                            <label htmlFor="mail">
                                {t('startupForm.email')}* :
                            </label>
                            <input
                                className="input w-350"
                                type="email"
                                {...register('contact.mail', {
                                    disabled: disabledFields,
                                    required: {
                                        value: true,
                                        message: t('common.errors.required')
                                    },
                                    pattern: {
                                        value: /^[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+$/gm,
                                        message: t('common.errors.invalidEmail')
                                    }
                                })}
                            />
                            {errors.contact?.mail && (
                                <div className="formError">
                                    {errors.contact.mail.message}
                                </div>
                            )}
                        </div>

                        <div className="field">
                            <label htmlFor="phoneNumber">
                                {t('startupForm.phone')}* :
                            </label>
                            <div className={`input w-350 ${disabledFields? 'disabled' :''}`}>                            
                                <Controller
                                    control={control}
                                    name="contact.phoneNumber"
                                    defaultValue={''}
                                    render={({
                                        field: { onChange, value }
                                    }) => (
                                        <PhoneInput
                                            placeholder={t("startupForm.phone")}
                                            disabled={disabledFields}
                                            value={value}
                                            styles={selectW720Styles}
                                            onChange={(newVal) => {
                                                console.log(newVal);
                                                onChange(newVal);
                                            }} />
                                    )}
                                    rules={{
                                        minLength: 3,
                                        required: {
                                            value: true,
                                            message: t('common.errors.required')
                                        }
                                    }}
                                />
                            </div>
                            {errors.contact?.phoneNumber && (
                                <div className="formError">
                                    {errors.contact.phoneNumber.message}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="is-flex gap">
                    </div>
                </fieldset>

                <div className="is-flex gap align-items-center">
                    {(!startupFormState.startup || !startupFormState.startup.spExhibitorId) && (
                        <Button
                            type="submit"
                            className="button gradient-vvt"
                            icon={<i className="fas fa-save"></i>}
                            label={t('common.save.title')}
                            disabled={!canSave || isLoading}
                        />
                    )}
                    <Button
                        type="button"
                        className="button gradient-vvt"
                        icon={<i className="fas fa-paper-plane"></i>}
                        onClick={handleSaveAndPublish}
                        label={t('common.saveAndPublish.title')}
                        disabled={!canPublish || isLoading}
                    />
                    {isLoading && (
                        <TailSpin
                            color="#1d6ff7"
                            height={30}
                            width={30}
                            wrapperClass="loader"
                        />
                    )}
                </div>
                <div className="is-flex smallgap align-items-center margin"><i className="fas fa-save"></i> {t('common.save.title')}: {t('common.save.description')}</div>
                <div className="is-flex smallgap align-items-center margin"><i className="fas fa-paper-plane"></i> {t('common.saveAndPublish.title')}: {t('common.saveAndPublish.description')}</div>
                <div>{t('common.saveWarning')}</div>
            </form>
        </>
    );
};

export default StartupForm;