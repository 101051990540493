import React, {
    ReactNode,
    useEffect,
    useRef,
} from 'react';
interface ModalProps {
    children: ReactNode;
    onClose?: () => void;
}

const Modal = ({
    children,
    onClose,
}: ModalProps) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        ref.current?.focus();

        document.addEventListener('keydown', e => {
            if (e.key === 'Escape' && onClose) {
                onClose();
            }
        });
    }, [onClose]);

    return (
        <div className="modal shadow" role="alertdialog" aria-modal="true" ref={ref} tabIndex={0}>
            {children}
        </div>
    );
};

export default Modal;