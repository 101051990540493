import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { Language } from "../enums/Language";
import translationEN from './en.json';
import translationFR from './fr.json';

const lang = Language.EN;

// Get language from URL
const params: any = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop as string),
});

const resources = {
    en: {
        translation: translationEN
    },
    fr: {
        translation: translationFR
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: params.lang || lang,
        keySeparator: ".",
        interpolation: {
            escapeValue: false
        }
    });

  export default i18n;